import { MIME_TYPE } from '../../../../Utils/globalEnums'

export const acceptedFileFormats = `${MIME_TYPE.IMAGE_PNG}, ${MIME_TYPE.IMAGE_JPEG}, .rtf, .pdf, .doc, .docx, ${MIME_TYPE.APPLICATION_WORD}, ${MIME_TYPE.APPLICATION_DOCUMENT}, .pages, .csv, .xlsx, .xls, ${MIME_TYPE.APPLICATION_EXCEL}, ${MIME_TYPE.APPLICATION_SPREADSHEET}, .ppt, .pptx, .key, .numbers`

export const MAX_UPLOAD_MB = 100

export type EvidenceFormData = {
  mime: string
  body: string | File
}

export enum AddEvidenceErrorType {
  CAPTION = 'caption',
  DATE = 'date',
  EVIDENCE = 'evidence',
}

export type AddEvidenceErrors = {
  captionError: string
  dateError: string
  evidenceError: string
}
