import React, { useContext, useEffect, useState } from 'react'
import { StandardOpenModalButton } from 'src/components/ModalForms/ModalButtons'
import { Range } from 'react-date-range'
import { getEvidenceDateRangeForAudit } from 'src/pages/Controls/ControlsShowPage/ControlsShowPage.helpers'
import { StandardButtonSize, StandardButtonVariant } from '../../Buttons'
import { ModalFormId } from '../../../ModalForms'
import { AddEvidenceForm } from '../../../ModalForms/Evidence/AddEvidence/AddEvidenceForm'
import { ReceptorScanSpinner } from '../../../../Throbber/ReceptorScanSpinner'
import { AddConnectionModalToggle } from '../../../../pages/Controls/ControlsShowPage/ConnectedGrid'
import ReceptorScanContext from '../../../../context/ReceptorScanStatus'
import { ShowLatestDateFilter } from '../../TrusteroDateRange/ShowLatestDateFilter'
import { FlexRow } from '../../Flex'
import {
  TabPanelButtonGroup,
  TabPanelNoteHeader,
  TabPanelNoteItem,
} from './styles'
import { EvidenceTabNoteProps } from './TabNote.components'

export const EvidenceTabNote = ({
  modelId,
  controlId,
  name,
  currentAudit,
  showLatest,
  setShowLatest,
  isNotApplicable,
}: EvidenceTabNoteProps): JSX.Element => {
  const { receptorScanRunning } = useContext(ReceptorScanContext)
  const [dateRange, dateRangeSet] = useState<Range>({
    startDate: undefined,
    endDate: undefined,
  })

  useEffect(() => {
    if (currentAudit) {
      dateRangeSet(getEvidenceDateRangeForAudit(currentAudit))
    }
  }, [currentAudit])

  const addEvidenceModalId = isNotApplicable
    ? ModalFormId.NA_INFO
    : ModalFormId.ADD_EVIDENCE
  const addConnectionModalId = isNotApplicable
    ? ModalFormId.NA_INFO
    : ModalFormId.ADD_EVIDENCE_GENERATOR_EVIDENCE_TAB

  return (
    <>
      <TabPanelNoteItem>
        <TabPanelNoteHeader>
          <TabPanelButtonGroup>
            <FlexRow gap={2}>
              <StandardOpenModalButton
                modalId={addEvidenceModalId}
                text="Add Evidence"
                size={StandardButtonSize.SMALL}
              />
              <AddConnectionModalToggle
                modalFormId={addConnectionModalId}
                buttonVariant={StandardButtonVariant.SECONDARY}
                controlId={controlId}
                isNotApplicable={isNotApplicable}
              />
            </FlexRow>
          </TabPanelButtonGroup>
          <ShowLatestDateFilter
            showLatest={showLatest}
            setShowLatest={setShowLatest}
            currentAudit={currentAudit?.getId()}
            dateRange={dateRange}
            setDateRange={dateRangeSet}
            tooltipBody={`Show only the latest evidence collected organized by their respective captions${
              currentAudit ? ' (within the audit period).' : '.'
            }`}
            additionalToolTip={!!currentAudit}
            additionalToolTipBody="Auditors only consider evidence and tests from within the date range of your current audit."
          />
        </TabPanelNoteHeader>
        <ReceptorScanSpinner show={receptorScanRunning} />
      </TabPanelNoteItem>
      <AddEvidenceForm
        modelId={modelId}
        controlId={controlId}
        name={name}
        openOnDrag
      />
    </>
  )
}
