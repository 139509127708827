import { GatekeeperFlag, IsGatekeeperEnabled } from '../../context/Gatekeeper'
import { getAllReceptorTemplates, Receptor } from '../../xgenerated'

export const getAllReceptorTemplatesFiltered = (): Receptor[] => {
  let receptorTemplates = getAllReceptorTemplates()
  // code to filter out receptor by modelid
  if (!IsGatekeeperEnabled(GatekeeperFlag.MIRADORE_RECEPTOR)) {
    receptorTemplates = receptorTemplates.filter(
      (receptorTemplate) => receptorTemplate.modelid !== 'trr-miradore',
    )
  }
  if (!IsGatekeeperEnabled(GatekeeperFlag.JUMPCLOUD_RECEPTOR)) {
    receptorTemplates = receptorTemplates.filter(
      (receptorTemplate) => receptorTemplate.modelid !== 'trr-jumpcloud',
    )
  }
  if (!IsGatekeeperEnabled(GatekeeperFlag.KNOWBE4_RECEPTOR)) {
    receptorTemplates = receptorTemplates.filter(
      (receptorTemplate) => receptorTemplate.modelid !== 'trr-knowbe4',
    )
  }
  receptorTemplates = receptorTemplates.filter(
    (receptorTemplate) => receptorTemplate.modelid !== 'trl-gdrive',
  )
  if (!IsGatekeeperEnabled(GatekeeperFlag.ANECDOTES_RECEPTOR)) {
    receptorTemplates = receptorTemplates.filter(
      (receptorTemplate) => receptorTemplate.modelid !== 'trr-anecdotes',
    )
  }
  return receptorTemplates
}
