import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import log from 'loglevel'
import { Spinner } from 'src/Throbber'
import { BASE_ROUTES } from 'src/components/Reusable/prevNext/usePrevNext'
import { ROOT_ID_PARAM_TYPE } from 'src/components/Reusable/RootPage/RootPage.constants'
import { PrevNextButtons } from 'src/components/Reusable/prevNext'
import { PrevNextContainer } from 'src/components/Reusable/prevNext/prevNext.styles'
import {
  SecondaryButton,
  StandardButton,
  StandardButtonSize,
  StandardButtonVariant,
} from 'src/components/Reusable/Buttons'
import { useModalState } from 'src/Modal/ModalStateContext'
import { ModalFormId } from 'src/components/ModalForms'
import { showInfoToast } from 'src/Utils/helpers/toast'
import {
  StyledSectionContainer,
  StyledSectionHeader,
} from 'src/components/PageLayout/ShowPage/showpage.styles'
import formatDate from 'src/Utils/formatDate'
import { Service } from 'src/xgenerated'
import { Grid } from 'src/components/Reusable/Grid'
import { FlexAlign, FlexRow } from 'src/components/Reusable/Flex'
import { useCurrentReceptor } from 'src/context/FormContext/CurrentReceptorContext'
import { CUSTOM_RECEPTOR_ID } from 'src/Utils/Receptors/receptors.constants'
import { useGetReceptorToken } from 'src/components/async/useGetReceptorToken'
import { useCopyToClipboard } from 'usehooks-ts/dist/useCopyToClipboard'
import { ModelID } from '@trustero/trustero-api-web/lib/account/account_pb'
import { CopyIcon } from 'src/components/Icons/Basic/CopyIcon'
import { Tabs } from 'src/components/Reusable/Tabs'
import { StandardOpenModalButton } from 'src/components/ModalForms/ModalButtons'
import { useReceptorScanContext } from 'src/context/ReceptorScanStatus'
import { useHasRequiredPermissions } from 'src/app/AppAuth/AppAuth.hooks'
import { PERMISSIONS } from 'src/config/roleConfig'
import { TableListLoader } from 'src/components/Reusable/ContentLoaders/TableListLoader'
import { useServicesForReceptor } from '../Services/Services.hooks'
import { getActiveServices } from '../Services/services.helpers'
import {
  ServicesTabGridHeader,
  ServicesTabGridRow,
} from '../Services/grid/ServicesGrid.components'
import {
  CustomReceptorButtonContainer,
  CustomReceptorButtonWrapper,
} from '../Services/ReceptorsPage/styles'
import { useRunAccountTests } from '../Controls/ControlsShowPage/TestGrid/TestGrid.hooks'
import {
  getAccountReceptors,
  getControlsTab,
  getCustomReceptorTab,
  getServicesTab,
} from './receptors.helpers'
import { useReceptors, useScanReceptor } from './receptors.hooks'
import {
  ReceptorReadyIcon,
  ReceptorStatusIcon,
} from './grid/ReceptorsGrid.components'
import {
  EditReceptorButton,
  EditReceptorIcon,
  IconSectionContainer,
  InsufficientPermissionsIcon,
  ScanReceptorsButtonContainer,
} from './receptors.styles'
import { useReceptorsContext } from './context/ReceptorsContext'

export const ReceptorsPrevNext = (): JSX.Element => {
  const location = useLocation()
  const { data, isLoading, error } = useReceptors()

  if (isLoading) {
    return <Spinner size="s" color="primary" />
  } else if (error) {
    log.error('Error fetching receptors in the PrevNext component', error)
    return <></>
  } else if (!data) {
    return <></>
  }

  const receptors = data.getReceptorsList()
  const accountReceptors = getAccountReceptors(receptors, location.search)
  const receptorIds = accountReceptors.map((receptor) => receptor.id)

  return (
    <PrevNextContainer>
      <PrevNextButtons
        baseRoute={BASE_ROUTES.RECEPTORS}
        idArray={receptorIds}
        param={ROOT_ID_PARAM_TYPE.RECEPTOR_ID}
      />
    </PrevNextContainer>
  )
}

export const ScanReceptorButton = ({
  buttonSize,
  buttonVariant,
}: {
  buttonSize: StandardButtonSize
  buttonVariant: StandardButtonVariant
}): JSX.Element => {
  const { scanInProgressState } = useReceptorScanContext()
  const { receptor } = useReceptorsContext()
  const scanReceptor = useScanReceptor()
  const isScanning = useRef<boolean>(false)
  const [showScanning, setShowScanning] = useState<boolean>(false)
  const hasScanPermissions = useHasRequiredPermissions([
    PERMISSIONS.READ,
    PERMISSIONS.EDIT,
  ])

  useEffect(() => {
    if (!receptor) return
    const receptorId = receptor.id
    if (isScanning.current && !scanInProgressState[receptorId]) {
      isScanning.current = false
      setShowScanning(false)
    }
    if (scanInProgressState[receptorId]) {
      isScanning.current = true
      setShowScanning(true)
    }
  }, [scanInProgressState, receptor])

  if (!receptor) return <></>

  const { id, modelid, isenabled, iscredvalid } = receptor

  const runScan = async (e: React.MouseEvent) => {
    try {
      e.preventDefault()
      if (isScanning.current) {
        return
      }
      setShowScanning(true)
      await scanReceptor({
        receptorId: id,
        receptorModelId: modelid,
      })
    } catch (err) {
      setShowScanning(false)
      showInfoToast(
        'Sorry, there was an issue scanning your receptors. Please try again later.',
      )
      log.error('Failed to complete receptor scan: ', err)
    }
  }

  return (
    <StandardButton
      variant={buttonVariant}
      buttonSize={buttonSize}
      disabled={
        !hasScanPermissions || showScanning || !isenabled || !iscredvalid
      }
      onClick={runScan}
    >
      <span>
        {showScanning ? (
          <FlexRow>
            Scanning...&nbsp;&nbsp;
            <Spinner size="s" color="primary" />
          </FlexRow>
        ) : (
          'Scan'
        )}
      </span>
    </StandardButton>
  )
}

export const ScanReceptorSection = (): JSX.Element => (
  <StyledSectionContainer>
    <ScanReceptorButton
      buttonSize={StandardButtonSize.MEDIUM}
      buttonVariant={StandardButtonVariant.SECONDARY}
    />
  </StyledSectionContainer>
)

export const StatusSection = (): JSX.Element => {
  const { receptor } = useReceptorsContext()

  if (!receptor) return <></>

  const { isenabled: isEnabled, lastrun: lastRun } = receptor
  const activatedText = isEnabled ? 'Activated.' : 'Deactivated.'
  // Only display last scan date if a valid value is present
  const lastScanText =
    lastRun > 0
      ? `Last scan ${formatDate(new Date(lastRun).toLocaleString(), true)}`
      : ''

  return (
    <StyledSectionContainer>
      <StyledSectionHeader>Status</StyledSectionHeader>
      <IconSectionContainer>
        <ReceptorStatusIcon isDeactivated={!isEnabled} />
        {activatedText} {lastScanText}
        {!isEnabled && (
          <EditReceptorButtonComponent
            text="Activate"
            modalId={ModalFormId.ACTIVATE_RECEPTOR}
          />
        )}
      </IconSectionContainer>
    </StyledSectionContainer>
  )
}

export const EditReceptorButtonComponent = ({
  text,
  modalId,
}: {
  text: string
  modalId: ModalFormId
}): JSX.Element => {
  const { openModal } = useModalState()
  const { setReceptor } = useCurrentReceptor()
  const { receptor } = useReceptorsContext()

  if (!receptor) return <></>

  return (
    <EditReceptorButton
      onClick={() => {
        setReceptor(receptor)
        openModal(modalId)
      }}
    >
      <>
        <EditReceptorIcon />
        {text}
      </>
    </EditReceptorButton>
  )
}

export const CredentialsSection = (): JSX.Element => {
  const { receptor } = useReceptorsContext()

  if (!receptor) return <></>

  const {
    iscredvalid: isCredValid,
    isenabled: isEnabled,
    exceptions,
  } = receptor
  const text = isEnabled ? (isCredValid ? 'Valid' : 'Invalid') : ''

  return (
    <StyledSectionContainer>
      <StyledSectionHeader>Credentials and Setup</StyledSectionHeader>
      <IconSectionContainer>
        {exceptions.length > 0 ? (
          <>
            <InsufficientPermissionsIcon />
            {exceptions}
          </>
        ) : (
          <>
            <ReceptorReadyIcon
              notReady={!isCredValid}
              isDeactivated={!isEnabled}
            />
            {text}
          </>
        )}
        <EditReceptorButtonComponent
          text="Edit Credentials and Setup"
          modalId={ModalFormId.ACTIVATE_RECEPTOR}
        />
      </IconSectionContainer>
    </StyledSectionContainer>
  )
}

export const ConfigSection = (): JSX.Element => {
  const { receptor } = useReceptorsContext()

  const hasConfig = !!receptor?.config_template?.length

  if (!receptor || !hasConfig) return <></>

  const { config, isenabled: isEnabled } = receptor
  const isConfigured = !!config.length
  const text = isConfigured ? 'Configured' : 'Not Configured'

  return (
    <StyledSectionContainer>
      <StyledSectionHeader>Configuration</StyledSectionHeader>
      <IconSectionContainer>
        <ReceptorReadyIcon
          notReady={!isConfigured}
          isDeactivated={!isEnabled}
        />
        {text}
        <EditReceptorButtonComponent
          text="Edit Configuration"
          modalId={ModalFormId.CONFIGURE_RECEPTOR}
        />
      </IconSectionContainer>
    </StyledSectionContainer>
  )
}

export const ServicesTabGrid = ({
  services,
}: {
  services: Service[]
}): JSX.Element => (
  <Grid gridTemplateColumns="repeat(2, 1fr) max-content">
    <ServicesTabGridHeader />
    {services.map((service: Service) => (
      <ServicesTabGridRow key={service.id} service={service} />
    ))}
  </Grid>
)

export const CustomReceptorDetails = ({
  receptorId,
}: {
  receptorId: string
}): JSX.Element => {
  const getReceptorToken = useGetReceptorToken()
  const [_, copyToClipboard] = useCopyToClipboard()

  const copyReceptorID = async () => {
    try {
      await copyToClipboard(receptorId)
      toast.success('Receptor ID copied to clipboard', { autoClose: 2_000 })
    } catch (e) {
      log.error('Error in ReceptorBody copyReceptorID', e)
    }
  }

  const copyReceptorToken = async () => {
    try {
      const token = await getReceptorToken(new ModelID().setId(receptorId))
      await copyToClipboard(token)
      toast.success('Receptor Token copied to clipboard', { autoClose: 2_000 })
    } catch (e) {
      log.error('Error in ReceptorBody copyReceptorToken', e)
    }
  }

  return (
    <CustomReceptorButtonContainer>
      Custom receptors automatically gather evidence from your systems guided by
      your own code.
      <CustomReceptorButtonWrapper>
        <StandardButton
          variant={StandardButtonVariant.SECONDARY}
          buttonSize={StandardButtonSize.MEDIUM}
          onClick={copyReceptorID}
        >
          <CopyIcon />
          Copy Receptor ID
        </StandardButton>
        <StandardButton
          variant={StandardButtonVariant.SECONDARY}
          buttonSize={StandardButtonSize.MEDIUM}
          onClick={copyReceptorToken}
        >
          <CopyIcon />
          Copy Receptor Token
        </StandardButton>
      </CustomReceptorButtonWrapper>
    </CustomReceptorButtonContainer>
  )
}

export const ReceptorShowPageTabs = (): JSX.Element => {
  const { receptor } = useReceptorsContext()
  const { data, isLoading, error } = useServicesForReceptor(
    receptor ? receptor.id : '',
    receptor ? receptor.modelid : '',
  )

  if (!receptor) return <></>

  if (isLoading) {
    return <TableListLoader />
  } else if (error) {
    showInfoToast(`Unable to load services for receptor. Please try again.`)
    log.error(
      `Error fetching services for receptor id ${receptor.id} in the ReceptorShowPageTabs component`,
    )
    return <></>
  } else if (!data) {
    showInfoToast(
      `Sorry, we couldn't load the services for receptor ${receptor.name}. Please try again or contact support.`,
    )
    return <></>
  }
  const services = data.getServicesList()
  const activeServices = getActiveServices(services, '')
  const tabsArray = []
  if (receptor.modelid !== CUSTOM_RECEPTOR_ID) {
    const servicesTab = getServicesTab(activeServices)
    const controlsTab = getControlsTab(
      receptor.modelid,
      receptor.automatedcontrolsList,
    )
    servicesTab && tabsArray.push(servicesTab)
    controlsTab && tabsArray.push(controlsTab)
  } else {
    tabsArray.push(getCustomReceptorTab(receptor.id))
  }

  return tabsArray.length ? <Tabs tabs={tabsArray} /> : <></>
}

export const ReceptorsIndexButtons = (): JSX.Element => {
  const { receptorScanRunning, setReceptorScanRunning } =
    useReceptorScanContext()
  const scanAllReceptors = useRunAccountTests()

  const runScan = async () => {
    try {
      if (receptorScanRunning) {
        return
      }
      setReceptorScanRunning(true)
      showInfoToast('Receptor scan started')
      await scanAllReceptors()
    } catch (err) {
      showInfoToast(
        'Sorry, there was an issue scanning your receptors. Please try again later.',
      )
      log.error('Failed to complete receptor scan: ', err)
    } finally {
      setReceptorScanRunning(false)
    }
  }

  const scanReceptorsButtonContent = receptorScanRunning ? (
    <ScanReceptorsButtonContainer>
      <Spinner size="s" color="primary" />
      Scanning...
    </ScanReceptorsButtonContainer>
  ) : (
    <>Scan All Receptors</>
  )

  return (
    <FlexRow gap={12} align={FlexAlign.FLEX_START}>
      <StandardOpenModalButton
        modalId={ModalFormId.SELECT_RECEPTOR}
        variant={StandardButtonVariant.PRIMARY}
        size={StandardButtonSize.SMALL}
        text="Add Receptor"
      />
      <SecondaryButton
        size={StandardButtonSize.SMALL}
        onClick={runScan}
        isDisabled={receptorScanRunning}
        content={scanReceptorsButtonContent}
      />
    </FlexRow>
  )
}
