import React, { useState } from 'react'
import {
  isExpandableRowItem,
  isAsyncExpandableRowItem,
  RowItemElement,
  RowItemExpandableElement,
  AsyncRowItemExpandableElement,
} from './RowItemProp'
import { AccordionGridRow } from './AccordionGridRow'

interface AccordionProps {
  gridTemplateColumns?: string
  items: RowItemExpandableElement[] | RowItemElement[]
  nothingToShow: JSX.Element
}

/**
 * AccordionGridBody
 *
 * This components is used for displaying expandable row grids, when the grid
 * and the widget to show when a row is expanded
 *
 * @param props
 * @constructor
 */
export const AccordionGridBody = (props: AccordionProps): JSX.Element => {
  const [accordionState, setAccordionState] = useState<number[]>(
    props.items.map(() => 1),
  )

  if (props.items.length === 0) {
    return props.nothingToShow
  }
  const initalGridOffset = 2
  const onExpanded = (idx: number, size: number) => {
    accordionState[idx] = size
    setAccordionState((prev) => [...prev, ...accordionState])
  }
  let gridRow = 0

  return (
    <>
      {props.items.map((item, idx) => {
        const offSet = 1 + accordionState[idx - 1] || initalGridOffset
        gridRow += idx === 0 ? initalGridOffset : offSet
        const isExpandable = isExpandableRowItem(item) && item.total > 1
        const total = isExpandableRowItem(item) ? item.total : 0

        return (
          <AccordionGridRow
            {...item}
            gridTemplateColumns={props.gridTemplateColumns}
            key={item.key}
            isExpanded={accordionState[idx] === total}
            expandedElement={isExpandable ? item.expandedItems : undefined}
            gridRow={gridRow}
            onExpanded={(state) => onExpanded(idx, state ? total : 0)}
          />
        )
      })}
    </>
  )
}

interface AsyncProps {
  gridTemplateColumns?: string
  items: AsyncRowItemExpandableElement[] | RowItemElement[]
  nothingToShow: JSX.Element
}
/**
 * AsyncAccordionGridBody
 *
 * @deprecated use AccordionGridBody instead
 *
 * This components is used for displaying expandable row grids, when the grid
 * and the widget to show when a row is expanded
 *
 * @param props
 * @constructor
 */
export function AsyncAccordionGridBody(props: AsyncProps): JSX.Element {
  const [accordionState, setAccordionState] = useState<number[]>(
    props.items.map(() => 1),
  )

  if (props.items.length === 0) {
    return props.nothingToShow
  }
  const initalGridOffset = 2
  const onExpanded = (idx: number, size: number) => {
    accordionState[idx] = size
    setAccordionState(accordionState)
  }
  let gridRow = 0

  return (
    <>
      {props.items.map((item, idx) => {
        const offSet = 1 + accordionState[idx - 1] || initalGridOffset
        gridRow += idx === 0 ? initalGridOffset : offSet
        const isExpandable = isAsyncExpandableRowItem(item) && item.total > 1
        const total = isAsyncExpandableRowItem(item) ? item.total : 0
        return (
          <AccordionGridRow
            {...item}
            gridTemplateColumns={props.gridTemplateColumns}
            key={item.key}
            isExpanded={true}
            expandedElement={isExpandable ? item.asyncCall : undefined}
            gridRow={gridRow}
            onExpanded={(state) => onExpanded(idx, state ? total : 0)}
          />
        )
      })}
    </>
  )
}
