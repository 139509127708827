import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Location } from 'history'
import queryString, { ParsedQuery } from 'query-string'
import Dropdown from 'react-bootstrap/Dropdown'
import { MenuPlaceholder } from 'src/components/Placeholders/MenuPlaceholder'
import { PERMISSIONS } from 'src/config/roleConfig'
import { CheckBoxInput } from '../../../Inputs/CheckBox'
import { CountChip } from '../../../Chips/BasicChip'
import { FilterName, FilterParam } from '../FilterBar.types'
import { FilterDropdownItem, FilterDropdownToggle } from '../FilterBar.styles'

export function FilterDropdown({
  filterParam,
  filterName,
  filterValues,
  isLoading,
}: {
  filterParam: FilterParam
  filterName: FilterName
  filterValues: Array<{ value: string; label: string }>
  isLoading?: boolean
}): JSX.Element {
  const location = useLocation()
  const searchParams = useMemo(() => {
    return queryString.parse(location.search, {
      arrayFormat: 'bracket',
    })
  }, [location])

  const appliedFilters = useMemo(() => {
    let appliedFilters: string[]
    const preprocessed = searchParams[filterParam]
    if (Array.isArray(preprocessed)) {
      appliedFilters = preprocessed
    } else {
      appliedFilters = preprocessed ? [preprocessed] : []
    }
    return appliedFilters
  }, [searchParams, filterParam])

  const isSmartCheckFilter = filterParam === FilterParam.SMART_CHECKS

  return (
    <Dropdown autoClose="outside">
      <Dropdown.Toggle
        as={FilterDropdownToggle}
        $isAi={isSmartCheckFilter}
        id={`${filterName}-dropdown`}
      >
        <p>{filterName}</p>
        {appliedFilters.length > 0 && (
          <CountChip ml="xxs" color="white" bg="fill.tertiary.dark">
            {appliedFilters.length}
          </CountChip>
        )}
      </Dropdown.Toggle>
      {isLoading ? (
        <Dropdown.Menu>
          <MenuPlaceholder height={108} />
        </Dropdown.Menu>
      ) : (
        <Dropdown.Menu>
          <DropdownItems
            filterParam={filterParam}
            filterValues={filterValues}
            location={location}
            searchParams={searchParams}
            appliedFilters={appliedFilters}
          />
        </Dropdown.Menu>
      )}
    </Dropdown>
  )
}

function DropdownItems({
  filterParam,
  filterValues,
  location,
  searchParams,
  appliedFilters,
}: {
  filterParam: FilterParam
  filterValues: Array<{ value: string; label: string }>
  location: Location
  searchParams: ParsedQuery
  appliedFilters: string[]
}): JSX.Element {
  const navigate = useNavigate()

  return (
    <>
      {filterValues.map((filterValue) => {
        const newAppliedFilters = new Set(appliedFilters)
        const isChecked = newAppliedFilters.has(filterValue.value)

        isChecked
          ? newAppliedFilters.delete(filterValue.value)
          : newAppliedFilters.add(filterValue.value)

        const href = queryString.stringifyUrl(
          {
            url: location.pathname,
            query: {
              ...searchParams,
              [filterParam]: Array.from(newAppliedFilters),
            },
          },
          { arrayFormat: 'bracket' },
        )

        return (
          <FilterDropdownItem key={filterValue.value} replace to={href}>
            <CheckBoxInput
              id={filterValue.value}
              label={filterValue.label}
              checked={isChecked}
              onClick={(e) => {
                e.stopPropagation()
              }}
              onChange={() => {
                navigate(href, { replace: true })
              }}
              tabIndex={-1}
              requiredPermissions={[PERMISSIONS.READ]}
            />
          </FilterDropdownItem>
        )
      })}
    </>
  )
}
