import React, { useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { TimeRange } from '@trustero/trustero-api-web/lib/common/time_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { themeGet } from '@styled-system/theme-get'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import styled from 'styled-components/macro'
import queryString from 'query-string'
import { useDeleteEvidence } from 'src/components/ModalForms/Evidence/useDeleteEvidence'
import { formatEvidenceCaption } from 'src/Utils/helpers/string.helpers'
import { FilterParam } from 'src/components/Reusable/IndexPage/FilterBar/FilterBar.types'
import { useHasEditPermissions } from 'src/app/AppAuth/AppAuth.hooks'
import { UrlFragments } from 'src/Utils/globalEnums'
import { GatekeeperFlag, IsGatekeeperEnabled } from 'src/context/Gatekeeper'
import { generatePermalink } from 'src/components/PageLayout/Permalink'
import { ScrollRestoringLink } from 'src/components/Reusable/Navigation/ScrollRestoringLink'
import { EvidenceRelevantDateButton } from 'src/pages/Evidence/evidence.components'
import { DATE_FORMATS } from 'src/Utils/dateConstants'
import { GridColumn, GridRow } from '../../../../components/Reusable/Grid'
import { Tooltip } from '../../../../components/Reusable/Tooltip'
import { Gravatar } from '../../../../components/Gravatar'
import { formatTimestamp } from '../../../../Utils/formatDate'
import {
  IconButton,
  IconButtonVariant,
} from '../../../../components/Reusable/Buttons'
import { ReactComponent as DeleteIcon } from '../../../../components/Icons/assets/delete-icon.svg'
import { useFileTypeIconForEvidence } from '../../../../components/ModalForms/FileType/useFileTypeIcon'
import { useViewEvidence } from '../../../../components/ModalForms/Evidence/ViewEvidenceForm'

export type EvidenceRowProps = {
  gridRow?: number
  controlId?: string
  timeRange?: TimeRange
  evidenceId: string
  contentId: string
  mime: string
  caption: string
  createdAt: Date
  actorName?: string
  actorEmail: string
  isDeleteDisabled: boolean
  isAutomated: boolean
  leftMargin?: string
  relevantDate?: Timestamp
  isViaRequest?: boolean
  isControlShowPage?: boolean
  columnType: 'button' | 'a'
  requestId?: string
  controlOID: string
  total?: number
}

const EvidenceRowIcon = styled.p`
  margin-left: ${themeGet('space.m')}px;
`

export const EvidenceRow = (props: EvidenceRowProps): JSX.Element => {
  const {
    evidenceId,
    requestId,
    caption,
    controlOID,
    total,
    isAutomated,
    relevantDate,
  } = props
  const { pageContext } = useParams()

  const location = useLocation()
  const deleteFunc = useDeleteEvidence({
    evidenceId,
    requestId,
    caption,
    controlId: controlOID,
    total,
  })
  const searchParams = useMemo(() => {
    return queryString.parse(location.search, {
      arrayFormat: 'bracket',
    })
  }, [location])
  const hasEditPermissions = useHasEditPermissions()
  const viewEvidence = useViewEvidence(props.evidenceId)
  const FileTypeIcon = useFileTypeIconForEvidence(
    props.isAutomated,
    props.actorEmail,
    props.mime,
  )

  const hasNewEvidence = IsGatekeeperEnabled(GatekeeperFlag.NEW_EVIDENCE_TABLE)

  const isViaRequest = props.isControlShowPage && props.isViaRequest
  const colCaption =
    formatEvidenceCaption(props.caption, props.isAutomated) +
    (isViaRequest ? ' - via Request' : '')
  const dataID = `evidenceDelete_${props.evidenceId}`

  const evidencePermalink = generatePermalink({
    pageContext: pageContext as string,
    modelType: MODEL_TYPE.EVIDENCE,
    modelId: evidenceId,
    isInternalLink: true,
  })

  const showLatest = !!total

  const evidenceBaseQuery = {
    ...searchParams,
    showLatest: [showLatest.toString()],
  }

  const evidenceQuery = controlOID
    ? {
        ...evidenceBaseQuery,
        [FilterParam.CONTROL]: [controlOID],
      }
    : requestId
    ? {
        ...evidenceBaseQuery,
        [FilterParam.REQUEST]: [requestId],
      }
    : evidenceBaseQuery

  const fragment = controlOID
    ? `#${UrlFragments.CONTROLS}`
    : requestId
    ? `#${UrlFragments.REQUESTS}`
    : ''

  const evidenceLink = `${queryString.stringifyUrl(
    {
      url: evidencePermalink,
      query: evidenceQuery,
    },
    { arrayFormat: 'bracket' },
  )}${fragment}`

  const handleRowClick = async (e: React.MouseEvent) => {
    if (hasNewEvidence) return
    e.preventDefault()
    viewEvidence(e)
  }

  const editableRelevantDate = !isAutomated && hasNewEvidence

  return (
    <GridRow
      as={hasNewEvidence ? ScrollRestoringLink : GridRow}
      to={hasNewEvidence ? evidenceLink : ''}
      onClick={!hasNewEvidence && handleRowClick}
    >
      <GridColumn
        gridRow={props.gridRow}
        {...(props.leftMargin ? { ml: props.leftMargin } : {})}
        px="0"
        pl="22px"
        pr="m"
      >
        <EvidenceRowIcon>
          <FileTypeIcon width="24px" height="24px" />
        </EvidenceRowIcon>
      </GridColumn>
      <GridColumn gridRow={props.gridRow}>{colCaption}</GridColumn>
      <Tooltip
        id={`${props.evidenceId}-${props.actorEmail}`}
        placement="top-end"
        tooltipBody={props.actorName ?? props.actorEmail}
      >
        <GridColumn gridRow={props.gridRow} justifyContent="center">
          <Gravatar
            email={props.actorEmail}
            emptyIsUnassigned={false}
            magicIcon
          />
        </GridColumn>
      </Tooltip>
      <GridColumn gridRow={props.gridRow}>
        {editableRelevantDate ? (
          <EvidenceRelevantDateButton
            evidenceId={evidenceId}
            relevantDate={relevantDate || new Timestamp()}
          />
        ) : (
          <>
            {props.relevantDate &&
              formatTimestamp(props.relevantDate, DATE_FORMATS.ISO_WITH_TIME)}
          </>
        )}
      </GridColumn>
      <GridColumn gridRow={props.gridRow} onClick={handleRowClick}>
        {formatTimestamp(
          Timestamp.fromDate(props.createdAt),
          DATE_FORMATS.ISO_WITH_TIME,
        )}
      </GridColumn>
      <GridColumn
        gridRow={props.gridRow}
        style={{ paddingLeft: 0, cursor: 'pointer' }}
      >
        {!isViaRequest && (
          <IconButton
            disabled={props.isDeleteDisabled || !hasEditPermissions}
            variant={IconButtonVariant.icon}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              deleteFunc()
            }}
            data-id={dataID}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </GridColumn>
    </GridRow>
  )
}
