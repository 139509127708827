import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Range } from 'react-date-range'
import log from 'loglevel'
import { useNavigate, useParams } from 'react-router-dom'
import { getServiceTemplate } from 'src/xgenerated/service'
import {
  ExclusionsToMarkdown,
  generatePdfBlob,
} from 'src/components/ModalForms/Evidence/ViewEvidenceForm/ViewEvidenceForm.utils'
import { Evidence } from '@trustero/trustero-api-web/lib/receptor_v1/receptor_pb'
import { Markup } from 'src/components/Reusable/Text/Markup'
import {
  Evidence as EvidenceProto,
  EvidenceGroup,
  ListEvidenceRequest,
  UpdateDocumentRequest,
  ListEvidenceGroupsRequest,
  Excluded,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons'
import { AuditRecord } from '@trustero/trustero-api-web/lib/audit/audit_pb'
import { isViewable } from 'src/components/ModalForms/Evidence/ViewEvidenceForm/ViewEvidenceForm.utils'
import { AutomaticEvidenceTable } from 'src/components/ModalForms/Evidence/ViewEvidenceForm/ViewEvidenceForm.components'
import { FlexAlign, FlexRow } from 'src/components/Reusable/Flex'
import { isEvidenceFile } from 'src/components/ModalForms/Evidence/evidenceUtils'
import { useFileTypeIcon } from 'src/components/ModalForms/FileType/useFileTypeIcon'
import { debounce, isFunction } from 'lodash'
import { documentBodyAsFile } from 'src/adapter/AttachmentAdapter'
import { Tabs } from 'src/components/Reusable/Tabs'
import { useExclusions } from 'src/components/async/document/useDocument'
import {
  CloseButton,
  ImagePreview,
} from 'src/components/Reusable/Document/DocumentViewer.components'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { Spinner } from 'src/Throbber'
import { Gravatar } from 'src/components/Gravatar'
import { formatTimestamp } from 'src/Utils/formatDate'
import { showInfoToast, ToastPrompts } from 'src/Utils/helpers/toast'
import { MIME_TYPE, UrlFragments } from 'src/Utils/globalEnums'
import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import { useDownloadEvidenceTable } from 'src/components/ModalForms/Evidence/ViewEvidenceForm/ViewEvidenceForm.hooks'
import {
  EvidenceTabContainer,
  FileDownloadBtn,
} from 'src/components/ModalForms/Evidence/ViewEvidenceForm/ViewEvidenceForm.styles'
import { PERMISSIONS } from 'src/config/roleConfig'
import FileSaver from 'file-saver'
import { DownloadIcon } from 'src/components/Icons'
import {
  getIsMultiPartMixedMime,
  getPluralization,
  openInNewTab,
} from 'src/Utils/globalHelpers'
import { EyeIcon } from 'src/components/Icons/Basic/EyeIcon'
import { TextButton } from 'src/components/Reusable/Buttons/TextButton'
import { useAuthorizedGrpcClient } from 'src/adapter'
import { useModalState } from 'src/Modal/ModalStateContext'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { ModalFormId } from 'src/components/ModalForms'
import { useEvidenceResults } from 'src/components/async/suggestions'
import { SelectDropdown } from 'src/components/Reusable/SelectDropdown/SelectDropdown'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { useInAudit } from 'src/context/AuditContext'
import { EvidenceAbsoluteRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import { DATE_FORMATS } from 'src/Utils/dateConstants'
import {
  ItemTitle,
  ShowPageSectionHeader,
} from 'src/components/PageLayout/ShowPage'
import { SelectItem } from 'src/components/Reusable/SelectDropdown/SelectDropdown.constants'
import { linkWithSearch } from 'src/components/PageLayout/Permalink'
import { EditableShowPageTitle } from 'src/components/Reusable/ShowPage/Title/EditableShowPageTitle'
import { InfoButton } from 'src/components/PageLayout/InfoButton'
import { Tooltip } from 'src/components/Reusable/Tooltip'
import { TooltipOverlayType } from 'src/components/Reusable/Tooltip/Tooltip'
import { TrusteroDateRange } from 'src/components/Reusable/TrusteroDateRange'
import { useInvalidateAudits } from 'src/components/async/model/audit/useAudit'
import { useCurrentEvidenceId } from 'src/context/FormContext/CurrentEvidenceContext'
import { GatekeeperFlag, IsGatekeeperEnabled } from 'src/context/Gatekeeper'
import {
  StandardButtonSize,
  StandardButtonVariant,
} from 'src/components/Reusable/Buttons'
import { ExpandButtonRow } from 'src/components/Reusable/Document/DocumentViewer.styles'
import { TableListLoader } from 'src/components/Reusable/ContentLoaders/TableListLoader'
import {
  EvidenceDropdownContainer,
  EvidenceFileInfoContainer,
  EvidenceFileName,
  EvidenceShowPageIcon,
  EvidenceShowTabBody,
  ManualEvidenceContainer,
  PropertiesGrid,
  PropertiesLabel,
  PropertiesRow,
  PropertiesValue,
  RelevantDateButton,
  EvidenceShowTabContainer,
  StyledAutomatedEvidenceModal,
  StyledAutomatedEvidenceBody,
} from './evidence.styles'
import {
  useEvidenceAudits,
  useEvidenceConfig,
  useEvidenceGroups,
  useUpdateEvidence,
} from './evidence.hooks'
import { ShowEvidenceAuditsModal } from './modals/ShowEvidenceAuditsModal'
import {
  generateEvidencePdfWithExclusions,
  getEvidenceVersionDropdownOptions,
} from './evidence.helpers'

export const AutomatedEvidenceIcon = (): JSX.Element => {
  return <EvidenceShowPageIcon icon={faWandMagicSparkles} />
}

export const EvidenceAuditsRow = ({
  evidence,
}: {
  evidence: EvidenceProto | EvidenceGroup
}): JSX.Element => {
  const { data, isLoading, error } = useEvidenceAudits({
    caption: evidence.getCaption(),
    contentId: evidence.getContentId(),
    discoveryId: evidence.getDiscoveryId(),
    relevantDate: evidence.getRelevantDate() || new Timestamp(),
  })
  const { openModal } = useModalState()
  if (isLoading) {
    return <Spinner size="l" color="primary" />
  } else if (!data) {
    if (error) {
      log.error('Error loading evidence audits', error)
    }
    return <></>
  }

  const audits = data.getItemsList()

  return (
    <>
      <PropertiesRow>
        <PropertiesLabel>Audits</PropertiesLabel>
        <PropertiesValue>
          <FlexRow gap={5}>
            <EyeIcon />
            <TextButton onClick={() => openModal(ModalFormId.EVIDENCE_AUDITS)}>
              {`${audits.length} ${getPluralization('audit', audits.length)}`}
            </TextButton>
          </FlexRow>
        </PropertiesValue>
      </PropertiesRow>
      <ShowEvidenceAuditsModal audits={audits} />
    </>
  )
}

export const EvidenceAddedByRow = ({
  evidence,
}: {
  evidence: EvidenceProto | EvidenceGroup
}): JSX.Element => {
  const actorEmail = evidence.getActorEmail()
  const isAutomated = evidence.getIsAutomated()

  return (
    <PropertiesRow>
      <PropertiesLabel>Added By</PropertiesLabel>
      <PropertiesValue>
        {isAutomated ? (
          <FlexRow gap={5}>
            <AutomatedEvidenceIcon />
            Receptor
          </FlexRow>
        ) : (
          <Gravatar email={actorEmail} size={20} />
        )}
      </PropertiesValue>
    </PropertiesRow>
  )
}

export const EvidenceRelevantDateComponent = ({
  evidence,
}: {
  evidence: EvidenceProto | EvidenceGroup
}): JSX.Element => {
  const mutate = useInvalidateAudits()
  const attachmentClient = useAuthorizedGrpcClient(AttachmentPromiseClient)

  const relevantDate = useMemo(() => {
    const relevantDateSeconds = evidence.getRelevantDate()?.getSeconds() || 0
    return new Date(relevantDateSeconds * 1000)
  }, [evidence])
  const [relevantDateAsRange, setRelevantDateAsRange] = useState<Range>({
    startDate: new Date(),
    endDate: relevantDate,
  })

  const updateRelevantDate = useMemo(() => {
    return debounce(async () => {
      try {
        const updateRequest = new UpdateDocumentRequest()
          .setId(evidence.getId())
          .setRelevantDate(
            Timestamp.fromDate(relevantDateAsRange.endDate as Date),
          )

        await attachmentClient.updateDocument(updateRequest)
        await mutate()
      } catch (e) {
        log.error(`Error updating relevant date`, e)
        showInfoToast(
          'Sorry, there was an error saving your changes. Please try again later.',
        )
      }
    }, 1000)
  }, [evidence, relevantDateAsRange, attachmentClient, mutate])

  useEffect(() => {
    if (relevantDate && relevantDate !== relevantDateAsRange.endDate) {
      updateRelevantDate()
    }
  }, [relevantDate, relevantDateAsRange, updateRelevantDate])

  return (
    <TrusteroDateRange
      single
      range={relevantDateAsRange}
      rangeSet={setRelevantDateAsRange}
    />
  )
}

export const EvidenceRelevantDateRow = ({
  evidence,
}: {
  evidence: EvidenceProto | EvidenceGroup
}): JSX.Element => {
  const hasRelevantDateForm = IsGatekeeperEnabled(
    GatekeeperFlag.RELEVANT_DATE_FORM,
  )
  const relevantDate = evidence.getRelevantDate()

  if (!relevantDate) return <></>

  const isEvidenceGroup = evidence instanceof EvidenceGroup

  return (
    <PropertiesRow>
      <PropertiesLabel>
        <FlexRow gap={8}>
          Relevant Date
          <Tooltip
            id="relevant-date-tooltip"
            overlayType={TooltipOverlayType.popover}
            tooltipBody="Relevant date that the evidence addresses. If your evidence covers a date range, use the end date."
          >
            <InfoButton />
          </Tooltip>
        </FlexRow>
      </PropertiesLabel>
      <PropertiesValue>
        {hasRelevantDateForm ? (
          <EvidenceRelevantDateButton
            evidenceId={evidence.getId()}
            relevantDate={relevantDate}
            isEvidenceGroup={isEvidenceGroup}
          />
        ) : (
          <EvidenceRelevantDateComponent evidence={evidence} />
        )}
      </PropertiesValue>
    </PropertiesRow>
  )
}

export const EvidencePropertiesBody = ({
  evidence,
}: {
  evidence: EvidenceProto | EvidenceGroup
}): JSX.Element => {
  const isManual = !evidence.getIsAutomated()
  const createdAt = evidence.getCreatedAt() || new Timestamp()
  const createdAtVerb = isManual ? 'Uploaded' : 'Created'

  return (
    <EvidenceShowTabBody>
      <PropertiesGrid>
        <EvidenceAddedByRow evidence={evidence} />
        <EvidenceAuditsRow evidence={evidence} />
        {isManual && <EvidenceRelevantDateRow evidence={evidence} />}
        <PropertiesRow>
          <PropertiesLabel>{`${createdAtVerb} Date`}</PropertiesLabel>
          <PropertiesValue>{formatTimestamp(createdAt)}</PropertiesValue>
        </PropertiesRow>
      </PropertiesGrid>
    </EvidenceShowTabBody>
  )
}

export const AutomaticEvidenceBody = ({
  body,
}: {
  body?: Uint8Array
}): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModal = (): void => {
    setIsModalOpen(!isModalOpen)
  }
  if (!body) return <></>
  const evidence: Evidence = Evidence.deserializeBinary(body)
  return (
    <>
      <ExpandButtonRow>
        <TextButton onClick={toggleModal}>Expand</TextButton>
      </ExpandButtonRow>
      <AutomaticEvidenceTable evidence={evidence} />
      {isModalOpen && (
        <StyledAutomatedEvidenceModal onClick={toggleModal}>
          <StyledAutomatedEvidenceBody>
            <AutomaticEvidenceTable evidence={evidence} />
          </StyledAutomatedEvidenceBody>
          <CloseButton />
        </StyledAutomatedEvidenceModal>
      )}
    </>
  )
}

export const EvidenceDocumentFooter = ({
  evidenceId,
  contentId,
  mime,
  caption,
  exclusions,
  body,
  isAutomated,
}: {
  evidenceId: string
  contentId: string
  mime: string
  caption: string
  exclusions?: Excluded[]
  body: Uint8Array | string | null
  isAutomated: boolean
}): JSX.Element => {
  const downloadEvidenceTable = useDownloadEvidenceTable({
    contentId,
    mime,
  })
  const { data: exclusionsData } = useExclusions(evidenceId, !exclusions)
  const isLink = mime === MIME_TYPE.TEXT_URI_LIST

  const downloadFile = async (): Promise<void> => {
    if (body) {
      const material = documentBodyAsFile(body, caption, mime)
      FileSaver.saveAs(material)
    }
  }
  const exclusionsList = useMemo(() => {
    if (exclusions) {
      return exclusions
    }
    return exclusionsData?.getExclusions()?.getExclusionsList() || []
  }, [exclusions, exclusionsData])

  const downloadEvidencePdf = async (): Promise<void> => {
    if (!body || !exclusionsData) return
    const pdfContent = await generateEvidencePdfWithExclusions(
      body,
      caption,
      exclusionsList,
    )
    const pdfBlob = await generatePdfBlob(pdfContent)
    FileSaver.saveAs(pdfBlob, `${caption}.pdf`)
  }

  return (
    <FlexRow justify={FlexAlign.FLEX_START} $mt={10} gap={16}>
      {!isAutomated ? (
        <>
          {isLink ? (
            <></>
          ) : (
            <FileDownloadBtn
              onClick={downloadFile}
              requiredPermissions={[PERMISSIONS.EXPORT]}
            >
              <DownloadIcon height={14} width={14} fontSize={14} />
              <span>Download File</span>
            </FileDownloadBtn>
          )}
        </>
      ) : (
        <>
          <FileDownloadBtn
            onClick={downloadEvidenceTable}
            requiredPermissions={[PERMISSIONS.EXPORT]}
          >
            <DownloadIcon height={14} width={14} fontSize={14} />
            <span>Download Table</span>
          </FileDownloadBtn>
          <FileDownloadBtn
            onClick={downloadEvidencePdf}
            requiredPermissions={[PERMISSIONS.EXPORT]}
          >
            <DownloadIcon height={14} width={14} fontSize={14} />
            <span>Download All Evidence</span>
          </FileDownloadBtn>
        </>
      )}
    </FlexRow>
  )
}

export const EvidenceBody = ({
  evidenceId,
  contentId,
  body,
  caption,
  mimeType,
  isAutomated,
}: {
  evidenceId: string
  contentId: string
  body: string | Uint8Array | null
  caption: string
  mimeType: string
  isAutomated: boolean
}): JSX.Element => {
  const [file, setFile] = useState<File | null>(null)
  useEffect(() => {
    if (body && mimeType) {
      const file = documentBodyAsFile(body, caption, mimeType)
      if (isViewable(file.type)) {
        setFile(file)
      }
    }
  }, [body, setFile, mimeType, caption])

  const showAutomated = isAutomated && body instanceof Uint8Array

  const isFile = isEvidenceFile(mimeType)

  return (
    <>
      {body ? (
        <EvidenceShowTabContainer>
          {showAutomated ? (
            <AutomaticEvidenceBody body={body} />
          ) : (
            <>
              {isFile && (
                // avoid allowing file preview click if not ready/available
                <>
                  {file ? (
                    <ImagePreview file={file} isEvidenceShowPage />
                  ) : (
                    <TableListLoader />
                  )}
                </>
              )}
            </>
          )}
          <EvidenceDocumentFooter
            evidenceId={evidenceId}
            contentId={contentId}
            mime={mimeType}
            caption={caption}
            body={body}
            isAutomated={isAutomated}
          />
        </EvidenceShowTabContainer>
      ) : (
        <TableListLoader />
      )}
    </>
  )
}

export const EvidenceExcludedBody = ({
  evidenceId,
  exclusions,
}: {
  evidenceId: string
  exclusions?: Excluded[]
}): JSX.Element => {
  const { data, isLoading } = useExclusions(evidenceId, !exclusions)
  const getServiceName = useCallback(
    (id: string) => getServiceTemplate(id)?.name || 'Unknown Service',
    [],
  )

  const exclusionBody = useMemo(() => {
    if (exclusions) {
      return ExclusionsToMarkdown(exclusions, getServiceName)
    }
    if (isLoading) {
      return 'Loading...'
    }
    const exclusionsList = data?.getExclusions()?.getExclusionsList() || []
    return ExclusionsToMarkdown(exclusionsList, getServiceName)
  }, [data, getServiceName, isLoading, exclusions])

  return (
    <EvidenceShowTabBody>
      <Markup markdown={exclusionBody} />
    </EvidenceShowTabBody>
  )
}

export const EvidenceSourceBody = ({
  sourcesMarkdown,
}: {
  sourcesMarkdown: string
}): JSX.Element => (
  <EvidenceShowTabBody>
    <Markup markdown={sourcesMarkdown} />
  </EvidenceShowTabBody>
)

export const EvidenceDocumentSection = ({
  evidence,
}: {
  evidence: EvidenceProto | EvidenceGroup
}): JSX.Element => (
  <>
    <ManualEvidenceSection evidence={evidence} />
    <EvidenceTabs evidence={evidence} />
  </>
)

export const EvidenceTabs = ({
  evidence,
}: {
  evidence: EvidenceProto | EvidenceGroup
}): JSX.Element => {
  const mime = evidence.getMime()
  const [mimeType, setMimeType] = useState<string>(mime)
  const [body, setBody] = useState<Uint8Array | string | null>(null)
  const [sourcesMarkdown, setSourcesMarkdown] = useState<string>('')
  const [isAutomated, setIsAutomated] = useState<boolean>(
    evidence.getIsAutomated(),
  )
  const [evidenceId, setEvidenceId] = useState<string>(evidence.getId())
  const contentId = evidence.getContentId()
  const caption = evidence.getCaption()
  const isMultipart = getIsMultiPartMixedMime(mime)
  const isEvidenceAutomated = evidence.getIsAutomated()
  const getEvidenceConfig = useEvidenceConfig(
    contentId,
    mime,
    isEvidenceAutomated,
    caption,
  )
  const isEvidenceGroup = evidence instanceof EvidenceGroup

  useEffect(() => {
    if (isFunction(getEvidenceConfig)) {
      const handleConfig = async () => {
        try {
          const config = await getEvidenceConfig()
          if (!config) return
          setBody(config.body)
          setMimeType(config.mimeType)
          setSourcesMarkdown(config.sourcesMarkdown)
          setIsAutomated(config.isAutomated)
        } catch (err) {
          log.error(
            `Error downloading document in EvidenceTabs - id: ${evidence.getId()}`,
            err,
          )
          showInfoToast(ToastPrompts.DOC_DOWNLOAD_ERROR)
        } finally {
          setEvidenceId(evidence.getId())
        }
      }
      handleConfig()
    }
  }, [getEvidenceConfig, evidence])

  if (evidenceId !== evidence.getId()) {
    return <TableListLoader />
  }

  const includeEvidenceBody = isAutomated || isViewable(mimeType)

  const evidenceTab = {
    title: 'Evidence',
    body: (
      <EvidenceBody
        evidenceId={evidence.getId()}
        contentId={contentId}
        body={body}
        caption={caption}
        mimeType={mimeType}
        isAutomated={isAutomated}
      />
    ),
  }

  const excludedTab = {
    title: 'Excluded',
    body: (
      <EvidenceExcludedBody
        evidenceId={evidence.getId()}
        exclusions={
          isEvidenceGroup
            ? evidence.getAllExclusions()?.getExclusionsList()
            : undefined
        }
      />
    ),
  }

  const sourceTab = {
    title: 'Source',
    body: <EvidenceSourceBody sourcesMarkdown={sourcesMarkdown} />,
  }

  const propertiesTab = {
    title: 'Properties',
    body: <EvidencePropertiesBody evidence={evidence} />,
  }

  const tabs = [propertiesTab]

  if (isAutomated || isMultipart) {
    tabs.unshift(sourceTab)
  }
  if (isAutomated) {
    tabs.unshift(excludedTab)
  }

  includeEvidenceBody && tabs.unshift(evidenceTab)

  return (
    <EvidenceTabContainer>
      <Tabs tabs={tabs} />
    </EvidenceTabContainer>
  )
}

export const EvidenceAuditRowItem = ({
  audit,
}: {
  audit: AuditRecord
}): JSX.Element => {
  const auditName = audit.getName()
  const startDate = audit.getStartDate()
  const endDate = audit.getEndDate()
  return (
    <li>
      {auditName}. {startDate ? formatTimestamp(startDate) : ''}
      {endDate ? `-${formatTimestamp(endDate)}` : ''}
    </li>
  )
}

export const EvidenceVersionDropdown = ({
  caption,
  relevantDate,
  controlId,
  requestId,
  fromControl,
  fromRequest,
}: {
  caption: string
  relevantDate: Timestamp
  controlId?: string
  requestId?: string
  fromControl?: boolean
  fromRequest?: boolean
}): JSX.Element => {
  const { auditId } = useInAudit()
  const { pageContext } = useParams()
  const navigate = useNavigate()
  const fromIndex = !fromControl && !fromRequest
  const request = useMemo(() => {
    const req = new ListEvidenceRequest().setCaption(
      new StringValue().setValue(caption),
    )
    controlId && req.setControlId(controlId)
    requestId && req.setDocumentRequestId(requestId)
    auditId && req.setAuditId(new StringValue().setValue(auditId))
    return req
  }, [caption, controlId, requestId, auditId])
  const groupReq = useMemo(() => {
    const req = new ListEvidenceGroupsRequest().setCaption(
      new StringValue().setValue(caption),
    )
    return req
  }, [caption])
  const { data, isLoading, error } = useEvidenceResults(request, !fromIndex)
  const {
    data: groupsData,
    isLoading: groupsLoading,
    error: groupsError,
  } = useEvidenceGroups(groupReq, fromIndex)

  const isDataLoading = isLoading || groupsLoading
  const noData = (!data && !fromIndex) || (!groupsData && fromIndex)
  const errorOccured = error || groupsError

  const relevantDateString = formatTimestamp(
    relevantDate,
    DATE_FORMATS.ISO_WITH_TIME,
  )
  if (isDataLoading) {
    return <Spinner size="l" color="primary" />
  } else if (noData) {
    if (errorOccured) {
      error && log.error('Error loading evidence versions', error)
      groupsError && log.error('Error loading evidence groups', groupsError)
    }
    return <></>
  }
  const items = getEvidenceVersionDropdownOptions(fromIndex ? groupsData : data)

  const onSelectCb = (values: SelectItem[]): void => {
    const selectedId = values[0]?.value
    const versionLink = linkWithSearch(
      `/${pageContext}/${EvidenceAbsoluteRoutes.SHOW}/${selectedId}`,
    )
    const fragment = fromControl
      ? `#${UrlFragments.CONTROLS}`
      : fromRequest
      ? `#${UrlFragments.REQUESTS}`
      : ''
    navigate(`${versionLink}${fragment}`)
  }

  return (
    <EvidenceDropdownContainer>
      <SelectDropdown
        label=""
        placeholder={relevantDateString}
        items={items}
        onSelectCb={onSelectCb}
        isDisabled={items.length < 2}
      />
    </EvidenceDropdownContainer>
  )
}

export const ManualEvidenceBody = ({
  body,
  isLink,
}: {
  body: string
  isLink: boolean
}): JSX.Element =>
  isLink ? (
    <TextButton
      onClick={(e) => {
        e.preventDefault()
        openInNewTab(body.trim())
      }}
    >
      {body.trim()}
    </TextButton>
  ) : (
    <Markup markdown={body} />
  )

export const EvidenceShowTitle = ({
  id,
  caption,
  isAutomated,
  contentId,
  requestId,
  discoveryId,
  isEvidenceGroup,
}: {
  id: string
  caption: string
  isAutomated: boolean
  contentId: string
  requestId?: string
  discoveryId?: string
  isEvidenceGroup: boolean
}): JSX.Element => {
  const updateEvidence = useUpdateEvidence({
    id,
    isEvidenceGroup,
    caption,
    contentId,
    discoveryId,
    requestId,
  })
  const onUpdate = async (newCaption: string): Promise<void> => {
    try {
      await updateEvidence({ newCaption: newCaption })
    } catch (e) {
      log.error(`Error updating evidence caption`, e)
      showInfoToast(
        'Sorry, there was an error saving your changes. Please try again later.',
      )
    }
  }

  return isAutomated ? (
    <ItemTitle>{caption}</ItemTitle>
  ) : (
    <EditableShowPageTitle
      name={caption}
      onUpdate={onUpdate}
      modelType={MODEL_TYPE.EVIDENCE}
      fieldName="caption"
    />
  )
}

export const ManualEvidenceSection = ({
  evidence,
}: {
  evidence: EvidenceProto | EvidenceGroup
}): JSX.Element => {
  const contentId = evidence.getContentId()
  const mime = evidence.getMime()
  const [evidenceId, setEvidenceId] = useState<string>(evidence.getId())
  const [body, setBody] = useState<string | Uint8Array | null>(null)
  const [file, setFile] = useState<File | null>(null)
  const [mimeType, setMimeType] = useState<string>(mime)
  const [isAutomated, setIsAutomated] = useState<boolean>(false)
  const isEvidenceAutomated = evidence.getIsAutomated()
  const caption = evidence.getCaption()
  const getEvidenceConfig = useEvidenceConfig(
    contentId,
    mime,
    isEvidenceAutomated,
    caption,
  )
  const isFile = useMemo(() => isEvidenceFile(mimeType), [mimeType])
  const FileTypeIcon = useFileTypeIcon({ mime: mimeType })

  useEffect(() => {
    if (isFunction(getEvidenceConfig)) {
      const handleDocumentBody = async () => {
        try {
          const config = await getEvidenceConfig()
          if (!config) return
          setBody(config.body)
          setMimeType(config.mimeType)
          setIsAutomated(config.isAutomated)
          setFile(config.file)
        } catch (err) {
          log.error(
            `Error downloading document body in EvidenceTabs - id: ${evidence.getId()}`,
            err,
          )
          showInfoToast(ToastPrompts.DOC_DOWNLOAD_ERROR)
        } finally {
          setEvidenceId(evidence.getId())
        }
      }
      handleDocumentBody()
    }

    return () => {
      setBody(null)
    }
  }, [evidence, getEvidenceConfig])

  if (isAutomated || !mimeType || isViewable(mimeType)) return <></>

  const downloadFile = async (): Promise<void> => {
    if (body) {
      const material = documentBodyAsFile(body, caption, mimeType)
      FileSaver.saveAs(material)
    }
  }
  const isLink = mimeType === MIME_TYPE.TEXT_URI_LIST

  if (evidenceId !== evidence.getId()) {
    return <TableListLoader />
  }

  return (
    <>
      {body ? (
        <ManualEvidenceContainer>
          <ShowPageSectionHeader> EVIDENCE </ShowPageSectionHeader>
          {isFile ? (
            <EvidenceFileInfoContainer onClick={downloadFile}>
              {file ? (
                <>
                  <FileTypeIcon />
                  <EvidenceFileName>{file.name}</EvidenceFileName>
                </>
              ) : (
                <Spinner size="m" color="primary" />
              )}
            </EvidenceFileInfoContainer>
          ) : (
            <ManualEvidenceBody body={body as string} isLink={isLink} />
          )}
          <EvidenceDocumentFooter
            evidenceId={evidence.getId()}
            contentId={contentId}
            mime={mimeType}
            caption={caption}
            body={body as Uint8Array}
            isAutomated={isAutomated}
          />
        </ManualEvidenceContainer>
      ) : (
        <TableListLoader />
      )}
    </>
  )
}

export const EvidenceRelevantDateButton = ({
  evidenceId,
  relevantDate,
  isEvidenceGroup,
}: {
  evidenceId: string
  relevantDate: Timestamp
  isEvidenceGroup?: boolean
}): JSX.Element => {
  const { openModal } = useModalState()
  const { setEvidenceId } = useCurrentEvidenceId()

  const onClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    e.stopPropagation()
    setEvidenceId(evidenceId)
    openModal(
      isEvidenceGroup
        ? ModalFormId.EVIDENCE_GROUP_RELEVANT_DATE
        : ModalFormId.RELEVANT_DATE,
    )
  }

  return (
    <RelevantDateButton
      buttonSize={StandardButtonSize.SMALL}
      variant={StandardButtonVariant.SECONDARY}
      onClick={onClick}
    >
      {formatTimestamp(relevantDate, DATE_FORMATS.ISO_WITH_TIME)}
    </RelevantDateButton>
  )
}
