import React, {
  ChangeEvent,
  FormEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import log from 'loglevel'
import {
  ACTOR_TYPE,
  Document,
  UpdateDocumentRequest,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import { Range } from 'react-date-range'
import { toast } from 'react-toastify'
import {
  useFetchDocumentBody,
  useGetDownloadUrl,
} from 'src/components/async/document/useDocument'
import { Spinner } from 'src/Throbber/Spinner'
import { FlexAlign, FlexColumn, FlexRow } from 'src/components/Reusable/Flex'
import { HUBSPOT } from 'src/Utils/hubspot/hubspot.utils'
import { Markup } from 'src/components/Reusable/Text/Markup'
import { ExternalLink } from 'src/components/Reusable/Text/Link'
import { P } from 'src/pages/SecurityQuestionnaire/securityQuestionnaire.styles'
import { ToastPrompts, showInfoToast } from 'src/Utils/helpers/toast'
import { documentBodyAsFile } from 'src/adapter/AttachmentAdapter'
import FileSaver from 'file-saver'
import { isFunction } from 'lodash'
import { formatEvidenceCaption } from 'src/Utils/helpers/string.helpers'
import { DerivedTextInput } from 'src/components/Reusable/Inputs'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { useInvalidatePolicyDocuments } from 'src/pages/Policies/Policies.hooks'
import { ImagePreview } from 'src/components/Reusable/Document/DocumentViewer.components'
import { useAuthorizedGrpcClient } from '../../../../adapter'
import { ModalFormId } from '../../ModalForm'
import { StyledLabel } from '../../../Reusable/Inputs/TextInput/styles'
import { TrusteroDateRange } from '../../../Reusable/TrusteroDateRange'
import { FileInfoContainer } from '../AddEvidence/AddEvidenceForm.styles'
import { dateFormatter } from '../../../../Utils/formatDate'
import { useHardEvidenceInvalidation } from '../../../../Utils/swrCacheInvalidation/useInvalidateEvidence'
import { dateToTimestamp } from '../../../../Utils/formatDate'
import { isEvidenceFile } from '../evidenceUtils'
import { useFileTypeIcon } from '../../FileType/useFileTypeIcon'
import {
  AutomaticEvidenceBody,
  ManualEvidenceBody,
} from './ViewEvidenceForm.components'
import {
  EvidenceHeader,
  StyledCaptionContainer,
} from './ViewEvidenceForm.styles'
import { isViewable } from './ViewEvidenceForm.utils'

const logger = log.getLogger('updateRelevantDate')

interface EvidenceModalProps {
  document: Document
  onHide: () => void
  isManual: boolean
  documentTitle?: string
  isPolicy?: boolean
}

export const EvidenceModal = ({
  document,
  onHide,
  isManual,
  documentTitle,
  isPolicy = false,
}: EvidenceModalProps): JSX.Element => {
  const [fileUrl, setFileUrl] = useState<string>()
  const [dateRange, setDateRange] = useState<Range>({
    startDate: new Date(),
    endDate: new Date(),
  })
  const [tmpCaption, setTmpCaption] = useState<string>(document.getCaption())
  const [updatingCaption, setUpdatingCaption] = useState<boolean>(false)
  const contentId = document.getContentid()
  const origContentId = useRef<string>('')
  const evidenceListInvalidator = useHardEvidenceInvalidation()
  const invalidatePolicyDocuments = useInvalidatePolicyDocuments()
  const attachmentClient = useAuthorizedGrpcClient(AttachmentPromiseClient)
  const evidenceMime = document.getMime()
  const isFile = isEvidenceFile(evidenceMime)
  const FileTypeIcon = useFileTypeIcon({ mime: evidenceMime })
  const documentId = document.getId()
  const mime = document.getMime()
  const exclusions = document.getAllexclusions()?.getExclusionsList() ?? []
  const evidenceDescription = document.getDescription().split('=====')[0]
  const evidenceCaption = formatEvidenceCaption(tmpCaption, !isManual)
  const getFileUrl = useGetDownloadUrl(contentId)
  const relevantDateInSeconds = document.getRelevantdate()?.getSeconds() ?? 0
  const fetchDocumentBody = useFetchDocumentBody(contentId, mime)
  const [file, setFile] = useState<File | null>(null)

  useEffect(() => {
    if (relevantDateInSeconds > 0) {
      setDateRange({
        startDate: new Date(),
        endDate: new Date(relevantDateInSeconds * 1000),
      })
    }
  }, [relevantDateInSeconds])

  useEffect(() => {
    const caption = document.getCaption()
    const mimeType = document.getMime()

    if (isFunction(fetchDocumentBody)) {
      const handleDocumentBody = async () => {
        try {
          const body = await fetchDocumentBody()
          if (body) {
            const file = documentBodyAsFile(body, caption, mimeType)
            if (isViewable(file.type)) {
              setFile(file)
            }
          }
        } catch (err) {
          log.error(
            `Error fetching document body in EvidenceModal - id: ${document.getId()} mime: ${mimeType}`,
            err,
          )
          showInfoToast(ToastPrompts.DOC_DOWNLOAD_ERROR)
        }
      }
      handleDocumentBody()
    }
  }, [document, fetchDocumentBody])

  // get current file url to preview in new tab upon contentId change
  // TODO: Fix this to read from a context map of contentId to fileUrl to avoid redundant RPC calls
  useEffect(() => {
    if (contentId === origContentId.current) return
    origContentId.current = contentId
    getFileUrl()
      .then((resp) => setFileUrl(resp?.getUrl()))
      .catch((err) => log.error('Error getting evidence file url', err))
  }, [contentId, getFileUrl, fileUrl])

  const allowCaptionUpdate = isPolicy || isManual
  const modalFormId = isPolicy
    ? ModalFormId.VIEW_DOCUMENT
    : ModalFormId.VIEW_EVIDENCE

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault()
      const initialDate = new Date(relevantDateInSeconds * 1000)
      if (
        (allowCaptionUpdate &&
          dateRange.endDate?.getTime() !== initialDate.getTime()) ||
        tmpCaption !== document.getCaption()
      ) {
        // Update relevant date
        const updateRequest = new UpdateDocumentRequest()
          .setId(document.getId())
          .setRelevantDate(dateToTimestamp(dateRange.endDate))
          .setCaption(new StringValue().setValue(tmpCaption))
        try {
          await attachmentClient.updateDocument(updateRequest)
        } catch (err) {
          logger.error('Error updating relevant date', err)
          toast.error(
            <div>{'There was a problem updating the relevant date'}</div>,
          )
          return
        }
        await evidenceListInvalidator()
        if (isPolicy) {
          await invalidatePolicyDocuments()
        }
      }
      onHide()
    },
    [
      relevantDateInSeconds,
      allowCaptionUpdate,
      dateRange.endDate,
      tmpCaption,
      document,
      onHide,
      evidenceListInvalidator,
      isPolicy,
      attachmentClient,
      invalidatePolicyDocuments,
    ],
  )

  const downloadFile = async (): Promise<void> => {
    let body = null
    try {
      body = await fetchDocumentBody()
      if (!body) {
        showInfoToast(ToastPrompts.EVIDENCE_DOWNLOAD_ERROR)
        return
      }
      const material = documentBodyAsFile(body, document.getCaption(), mime)
      FileSaver.saveAs(material)
    } catch (err) {
      log.error(`Error downloading evidence - contentId: ${contentId}`, err)
    }
  }

  return (
    <form id={modalFormId} onSubmit={onSubmit}>
      <StyledLabel form={modalFormId}>
        <StyledCaptionContainer
          allowEdit={allowCaptionUpdate && !updatingCaption}
          onClick={(e) => {
            e.stopPropagation()
            if (allowCaptionUpdate) {
              setUpdatingCaption(true)
            }
          }}
          onBlur={() => setUpdatingCaption(false)}
        >
          <b>Caption</b>
          {updatingCaption ? (
            <DerivedTextInput
              name="Caption"
              initVal={tmpCaption}
              placeholder="Update Caption"
              maxInputLength={255}
              customOnChange={({
                currentTarget,
              }: ChangeEvent<HTMLInputElement>) =>
                setTmpCaption(currentTarget.value)
              }
              isValid={!!tmpCaption.length}
              errorMessage="Please add a caption."
            />
          ) : (
            <EvidenceHeader>{evidenceCaption}</EvidenceHeader>
          )}
        </StyledCaptionContainer>
        {evidenceDescription && (
          <>
            <b>Description</b>
            <EvidenceHeader>
              <Markup markdown={evidenceDescription} />
            </EvidenceHeader>
          </>
        )}
      </StyledLabel>
      {isManual && (
        <FlexColumn align={FlexAlign.FLEX_START} $mb={10} gap={10}>
          <FlexRow
            justify={FlexAlign.FLEX_START}
            align={FlexAlign.CENTER}
            gap={6}
          >
            <P>
              Relevant Date. Useful when you&apos;re adding evidence outside the
              audit period.
            </P>
            <ExternalLink
              href={HUBSPOT.RELEVANT_DATE_DOCUMENTATION}
              text="Learn more"
            />
          </FlexRow>
          <TrusteroDateRange
            single
            range={dateRange}
            rangeSet={(p) => {
              setDateRange(p)
            }}
          />
        </FlexColumn>
      )}
      <StyledLabel
        form={ModalFormId.VIEW_EVIDENCE}
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
        position="relative"
      >
        {document.getActortype() !== ACTOR_TYPE.USER ? (
          <AutomaticEvidenceBody
            documentId={documentId}
            contentId={contentId}
            mime={mime}
            exclusions={exclusions}
          />
        ) : (
          <>
            {isFile ? (
              // avoid allowing file preview click if not ready/available
              fileUrl ? (
                <>
                  {isViewable(mime) && file ? (
                    <FlexColumn $mt={30} gap={10}>
                      <ImagePreview file={file} />
                      <p className="last-modified">
                        Uploaded{' '}
                        {dateFormatter(new Date(document.getCreatedat()))}
                      </p>
                    </FlexColumn>
                  ) : isViewable(mime) && !file ? (
                    <FileInfoContainer>
                      <Spinner size="l" color="primary" />
                    </FileInfoContainer>
                  ) : (
                    <div onClick={downloadFile}>
                      <FileInfoContainer>
                        <FileTypeIcon width="120px" height="120px" />
                        <p className="filename">{evidenceMime}</p>
                      </FileInfoContainer>
                    </div>
                  )}
                </>
              ) : (
                <FileInfoContainer>
                  <Spinner size="l" color="primary" />
                </FileInfoContainer>
              )
            ) : (
              <ManualEvidenceBody
                contentId={contentId}
                mime={mime}
                documentTitle={documentTitle}
              />
            )}
          </>
        )}
      </StyledLabel>
    </form>
  )
}
