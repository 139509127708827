import React, { FormEventHandler, useCallback, useEffect, useMemo } from 'react'
import { ModalForm, ModalFormId } from 'src/components/ModalForms/ModalForm'
import { Range } from 'react-date-range'
import { useCurrentEvidenceId } from 'src/context/FormContext/CurrentEvidenceContext'
import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import { useHideModal, useIsShowModal } from 'src/Modal/ModalStateContext'
import { useDocument } from 'src/components/async/document/useDocument'
import log from 'loglevel'
import { useAuthorizedGrpcClient } from 'src/adapter'
import { UpdateDocumentRequest } from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { showInfoToast } from 'src/Utils/helpers/toast'
import { useHardEvidenceInvalidation } from 'src/Utils/swrCacheInvalidation/useInvalidateEvidence'
import { RelevantDateForm } from './forms/RelevantDateForm'

export const RelevantDateModal = (): JSX.Element => {
  const { evidenceId, setEvidenceId } = useCurrentEvidenceId()
  const attachmentClient = useAuthorizedGrpcClient(AttachmentPromiseClient)
  const mutate = useHardEvidenceInvalidation()
  const [range, setRange] = React.useState<Range>({
    startDate: new Date(),
    endDate: new Date(),
  })
  const [controlId, setControlId] = React.useState<string | undefined>(
    undefined,
  )
  const onHide = () => {
    setRange({
      startDate: new Date(),
      endDate: new Date(),
    })
    setEvidenceId('')
  }
  const show = useIsShowModal(ModalFormId.RELEVANT_DATE)
  const hide = useHideModal({
    modalId: ModalFormId.RELEVANT_DATE,
    onHide,
  })

  const {
    data: document,
    isLoading: docIsLoading,
    error,
  } = useDocument({
    documentId: evidenceId,
    shouldFetch: !!evidenceId,
  })
  const relevantDateInSeconds = useMemo(
    () => document?.getRelevantdate()?.getSeconds() || 0,
    [document],
  )

  useEffect(() => {
    if (!relevantDateInSeconds || !document) return
    setRange({
      startDate: new Date(),
      endDate: new Date(relevantDateInSeconds * 1000),
    })
    setControlId(document.getSubjectid())
  }, [relevantDateInSeconds, document])

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault()
      const initialDate = new Date(relevantDateInSeconds * 1000)
      if (!document || !range.endDate) return
      if (range.endDate.getTime() !== initialDate.getTime()) {
        try {
          // Update relevant date
          const updateRequest = new UpdateDocumentRequest()
            .setId(document?.getId())
            .setRelevantDate(Timestamp.fromDate(range.endDate))
          await attachmentClient.updateDocument(updateRequest)
        } catch (err) {
          log.error('Error updating relevant date', err)
          showInfoToast('There was a problem updating the relevant date')
          return
        }
        await mutate()
      }
      hide()
    },
    [
      relevantDateInSeconds,
      range.endDate,
      document,
      attachmentClient,
      mutate,
      hide,
    ],
  )

  if (docIsLoading) {
    return <></>
  } else if (!document) {
    if (error) {
      log.error('Error fetching document in relevant date modal', error)
    }
    return <></>
  }

  return (
    <ModalForm
      show={show}
      hide={hide}
      formId={ModalFormId.RELEVANT_DATE}
      title="Relevant Date"
      enforceFocus={false}
    >
      <form id={ModalFormId.RELEVANT_DATE} onSubmit={onSubmit}>
        <RelevantDateForm
          range={range}
          setRange={setRange}
          controlIds={controlId ? [controlId] : []}
        />
      </form>
    </ModalForm>
  )
}
