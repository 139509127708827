import React, { useState } from 'react'
import log from 'loglevel'
import { RpcError } from 'grpc-web'
import { ItemTitle } from 'src/components/PageLayout/ShowPage'
import { EditOnClick } from 'src/components/Reusable/Inputs/EditOnClick'
import { StyledTextInput } from 'src/components/Reusable/Inputs/TextInput/styles'
import { ERROR_CODES } from 'src/Utils/globalEnums'
import { getErrorCode, getErrorMessage } from 'src/Utils/globalHelpers'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import {
  getLengthError,
  TITLE_LABELS,
  TITLE_VALIDATORS,
} from './ShowPageTitle.helpers'
import {
  ShowPageTitleError,
  StyledEditRow,
  StyledInputContainer,
  StyledNameInputContainer,
} from './ShowPageTitle.styles'

type ShowPageTitleErrors = {
  idError: string
  nameError: string
}
const NoShowPageErrors = {
  idError: '',
  nameError: '',
}

export const EditableShowPageTitle = ({
  name,
  onUpdate,
  modelType,
  customId,
  fieldName,
}: {
  name: string
  onUpdate: (name: string, customId?: string) => Promise<void>
  modelType: MODEL_TYPE
  customId?: string
  fieldName?: string
}): JSX.Element => {
  const [tempCustomId, setTempCustomId] = useState<string>(customId || '')
  const [tempName, setTempName] = useState<string>(name)
  const [errors, setErrors] = useState<ShowPageTitleErrors>(NoShowPageErrors)
  const LABELS = TITLE_LABELS(modelType, fieldName)

  const onCancel = () => {
    setTempName(name)
    setErrors(NoShowPageErrors)
    customId && setTempCustomId(customId)
  }

  const validateInputs = (newName: string, newCustomId: string): boolean => {
    if (newName.length > 200) {
      setErrors((prev) => ({
        ...prev,
        nameError: getLengthError(
          LABELS.MODEL_NAME,
          TITLE_VALIDATORS.NAME_MAX_LENGTH,
        ),
      }))
      return false
    } else if (!newName.length) {
      setErrors((prev) => ({ ...prev, nameError: LABELS.MISSING_NAME }))
      return false
    } else if (customId && !newCustomId.length) {
      setErrors((prev) => ({ ...prev, idError: LABELS.MISSING_MODEL_ID }))
      return false
    } else if (newCustomId.length > 10) {
      setErrors((prev) => ({
        ...prev,
        idError: getLengthError(
          LABELS.MODEL_ID,
          TITLE_VALIDATORS.MODEL_ID_MAX_LENGTH,
        ),
      }))
      return false
    }
    return true
  }

  const onSave = async (): Promise<boolean> => {
    const newName = tempName.trim()
    const newCustomId = tempCustomId.trim()
    // Validate inputs
    if (name === newName) {
      if (customId) {
        if (customId === newCustomId) {
          return true
        }
      } else {
        return true
      }
    }
    const isValid = validateInputs(newName, newCustomId)
    if (!isValid) {
      return false
    }
    try {
      await onUpdate(newName, newCustomId)
    } catch (err) {
      const resError = err as RpcError
      const isDuplicateError =
        getErrorCode(resError.message) === ERROR_CODES.DUPLICATE_ENTRY ||
        resError.code === 6
      if (isDuplicateError) {
        customId
          ? setErrors((prev) => ({ ...prev, idError: LABELS.UNIQUE_MODEL_ID }))
          : setErrors((prev) => ({
              ...prev,
              nameError: LABELS.UNIQUE_MODEL_NAME,
            }))
      } else {
        const errorMessage = getErrorMessage(resError).message
        customId
          ? setErrors((prev) => ({ ...prev, idError: errorMessage }))
          : setErrors((prev) => ({ ...prev, nameError: errorMessage }))
        log.error('Failed to update control show page:', resError)
      }
      return false
    }
    return true
  }

  return (
    <EditOnClick
      isInline
      onSaveControlClose={onSave}
      onCancel={onCancel}
      isEmpty={!tempName.length && !tempCustomId.length}
      editMode={
        <StyledEditRow>
          {customId && (
            <StyledInputContainer>
              <StyledTextInput
                name={LABELS.MODEL_ID_FIELD}
                onChange={(e) => setTempCustomId(e.target.value)}
                value={tempCustomId}
                showError={!tempCustomId}
              />
              <ShowPageTitleError showError={!!errors.idError}>
                {errors.idError}
              </ShowPageTitleError>
            </StyledInputContainer>
          )}
          <StyledNameInputContainer>
            <StyledTextInput
              name={LABELS.NAME_FIELD}
              onChange={(e) => setTempName(e.target.value)}
              value={tempName}
              showError={false}
            />
            <ShowPageTitleError showError={!!errors.nameError}>
              {errors.nameError}
            </ShowPageTitleError>
          </StyledNameInputContainer>
        </StyledEditRow>
      }
    >
      <ItemTitle>{`${customId || ''} ${name}`.trim()}</ItemTitle>
    </EditOnClick>
  )
}
