import React from 'react'
import { MIME_TYPE } from 'src/Utils/globalEnums'
import { FilterName, FilterParam } from '../FilterBar.types'
import { FilterDropdown } from './FilterDropdown'

enum FileTypeFilterNames {
  PDF = 'PDF',
  DOCUMENT = 'Document',
  SPREADSHEET = 'Spreadsheet',
  PRESENTATION = 'Presentation',
  CUSTOM = 'Custom',
  TEXT = 'Text',
  LINK = 'Link',
}

export enum FileTypeFilterValues {
  PDF = 'pdf',
  DOCUMENT = 'document',
  SPREADSHEET = 'spreadsheet',
  PRESENTATION = 'presentation',
  CUSTOM = '',
  TEXT = 'text',
  LINK = 'link',
}

export const FileTypeFilterNameToValue = Object.freeze({
  [FileTypeFilterNames.PDF]: FileTypeFilterValues.PDF,
  [FileTypeFilterNames.DOCUMENT]: FileTypeFilterValues.DOCUMENT,
  [FileTypeFilterNames.SPREADSHEET]: FileTypeFilterValues.SPREADSHEET,
  [FileTypeFilterNames.PRESENTATION]: FileTypeFilterValues.PRESENTATION,
  [FileTypeFilterNames.CUSTOM]: FileTypeFilterValues.CUSTOM,
  [FileTypeFilterNames.TEXT]: FileTypeFilterValues.TEXT,
  [FileTypeFilterNames.LINK]: FileTypeFilterValues.LINK,
})

export const FileTypeFilterValueToMimeTypes = Object.freeze({
  [FileTypeFilterValues.PDF]: [MIME_TYPE.APPLICATION_PDF],
  [FileTypeFilterValues.DOCUMENT]: [
    MIME_TYPE.APPLICATION_DOCUMENT,
    MIME_TYPE.APPLICATION_WORD,
    MIME_TYPE.APPLICATION_RTF,
  ],
  [FileTypeFilterValues.SPREADSHEET]: [
    MIME_TYPE.APPLICATION_SPREADSHEET,
    MIME_TYPE.APPLICATION_EXCEL,
    MIME_TYPE.APPLICATION_VND_OPENXML_SPREADSHEET,
    MIME_TYPE.TEXT_CSV,
  ],
  [FileTypeFilterValues.PRESENTATION]: [
    MIME_TYPE.APPLE_KEYNOTE,
    MIME_TYPE.APPLE_KEYNOTE_2,
  ],
  [FileTypeFilterValues.CUSTOM]: [],
  [FileTypeFilterValues.TEXT]: [MIME_TYPE.TEXT_MARKDOWN],
  [FileTypeFilterValues.LINK]: [MIME_TYPE.TEXT_URI_LIST],
})

export const FileTypeFilterDropdown = (): JSX.Element => {
  const filterValues = [
    ...Object.values(FileTypeFilterNames)
      .filter(
        (filterName: FileTypeFilterNames) =>
          filterName !== FileTypeFilterNames.CUSTOM,
      )
      .map((filterName: FileTypeFilterNames) => ({
        value: FileTypeFilterNameToValue[filterName],
        label: filterName,
      })),
  ]

  return (
    <FilterDropdown
      filterParam={FilterParam.FILE_TYPE}
      filterName={FilterName.FILE_TYPE}
      filterValues={filterValues}
    />
  )
}
