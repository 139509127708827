import React from 'react'
import { useHref } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import styled from 'styled-components/macro'
import {
  compose,
  height,
  top,
  HeightProps,
  padding,
  TopProps,
} from 'styled-system'
import { FilterBarConfigs } from 'src/components/Reusable/IndexPage/FilterBar/FilterBar.constants'
import { FilterBar } from 'src/components/Reusable/IndexPage/FilterBar'
import { INDEX_PAGE_WITH_FILTERS } from 'src/components/Reusable/IndexPage/IndexPage.constants'
import { StandardOpenModalButton } from 'src/components/ModalForms/ModalButtons'
import { useDocumentRequestCount } from 'src/components/async/DocumentRequest/useDocumentRequests'
import { useCurrentAudit } from 'src/components/async/model/audit'
import { PERMISSIONS } from 'src/config/roleConfig'
import { TableListLoader } from 'src/components/Reusable/ContentLoaders/TableListLoader'
import { PageHeader } from '../../../components/PageLayout/PageHeader'
import { ScopeTabs } from '../../../components/PageLayout/ScopeTabs'
import {
  SubpageNav,
  SubpageNavButtonContainer,
} from '../../../components/PageLayout/PageLayout.styles'
import {
  filterBarHeight,
  headerHeight,
} from '../../../components/PageLayout/dimensionUtils'
import { InfoLinkSection } from '../../../components/Reusable/Text/InfoLink.styles'
import { useDocumentRequests } from '../../../components/async/DocumentRequest'
import { ModalFormId } from '../../../components/ModalForms'
import {
  StandardButtonSize,
  StandardButtonVariant,
} from '../../../components/Reusable/Buttons'
import { ChooseRequestUploadType } from '../../../components/ModalForms/Requests/ChooseRequestUploadType/ChooseRequestUploadType'
import { NoResults } from '../../../components/PageLayout/NoResults'
import { useDRLRequest } from '../requests.hooks'
import { RequestsIndexGrid } from './RequestsIndexGrid'
import { REQUEST_GRID_LOCATIONS } from './RequestsIndexPage.constants'

const RequestsNav = styled.nav.attrs({
  pt: 'xs',
})<Required<TopProps & HeightProps>>`
  ${compose(top, height, padding)}
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 2;
  background-color: white;
`

const RequestsContent = styled.article.attrs({
  px: 'l',
})`
  ${padding}
  position: relative;
  background-color: white;
`
const RequestsPageHeader = ({
  title,
  showTabs,
}: {
  title: string
  showTabs: boolean
}): JSX.Element => {
  return (
    <PageHeader
      title={title}
      subHeader={showTabs ? <ScopeTabs></ScopeTabs> : <SubpageNav></SubpageNav>}
    />
  )
}

const NavButtons = (): JSX.Element => {
  return (
    <SubpageNavButtonContainer>
      <StandardOpenModalButton
        variant={StandardButtonVariant.PRIMARY}
        size={StandardButtonSize.SMALL}
        modalId={ModalFormId.CHOOSE_REQUEST_UPLOAD_TYPE}
        requiredPermissions={[PERMISSIONS.AUDITOR_ACTION]}
      >
        Add Request
      </StandardOpenModalButton>
    </SubpageNavButtonContainer>
  )
}

export const RequestsIndexPage = (): JSX.Element => {
  const request = useDRLRequest()
  const { data, isLoading } = useDocumentRequests(request)
  const { data: audit, isLoading: isAuditLoading } = useCurrentAudit()
  const auditId = audit?.getId()
  const { data: count, isLoading: isCountLoading } = useDocumentRequestCount(
    auditId || '',
  )
  const infoLink = useHref({
    pathname: `../../help/overview`,
    hash: 'requests',
  })
  if (!data || isLoading || !count || isCountLoading || isAuditLoading) {
    return (
      <>
        <ChooseRequestUploadType />
        <RequestsPageHeader title={`- Requests`} showTabs={false} />
        <RequestsContent>
          <RequestsNav top={headerHeight} height={filterBarHeight}>
            <FilterBar
              config={FilterBarConfigs[INDEX_PAGE_WITH_FILTERS.REQUESTS]}
            />
            <NavButtons />
          </RequestsNav>
          <InfoLinkSection>
            Enter requests for evidence, then distribute evidence by adding
            evidence and linking controls.{' '}
            <HashLink to={infoLink}>Learn more</HashLink>
          </InfoLinkSection>
          <TableListLoader />
        </RequestsContent>
      </>
    )
  }

  const requests = data.getItemsList()
  const noDocumentRequests = count.getCount() === 0
  const auditName = audit?.getName() || 'Audit'

  return (
    <>
      <ChooseRequestUploadType />
      <RequestsPageHeader title={`${auditName} Requests`} showTabs={false} />
      <RequestsContent>
        <RequestsNav top={headerHeight} height={filterBarHeight}>
          <FilterBar
            config={FilterBarConfigs[INDEX_PAGE_WITH_FILTERS.REQUESTS]}
          />
          <NavButtons />
        </RequestsNav>
        <InfoLinkSection>
          Enter requests for evidence, then distribute evidence by adding
          evidence and linking controls.{' '}
          <HashLink to={infoLink}>Learn more</HashLink>
        </InfoLinkSection>
        {noDocumentRequests ? (
          <NoResults
            title={"You don't have any document requests for this audit yet."}
            buttons={
              <StandardOpenModalButton
                variant={StandardButtonVariant.PRIMARY}
                size={StandardButtonSize.MEDIUM}
                text="Add Request"
                requiredPermissions={[PERMISSIONS.AUDITOR_ACTION]}
                modalId={ModalFormId.CHOOSE_REQUEST_UPLOAD_TYPE}
              />
            }
            largeTitle={true}
          />
        ) : (
          <RequestsIndexGrid
            requests={requests}
            location={REQUEST_GRID_LOCATIONS.REQUESTS_INDEX}
            stickyHeader
          />
        )}
      </RequestsContent>
    </>
  )
}
