import {
  ControlFilter,
  EvidenceGroup,
  GetLatestEvidenceGroupsRequest,
  GetLatestEvidenceRequest,
  ListEvidenceGroupsRequest,
  ListEvidenceRequest,
  Evidence as EvidenceProto,
  Excluded,
  ListEvidenceResponse,
  ListEvidenceGroupsResponse,
  SourceFilter,
  FileTypeFilter,
  ActorFilter,
  EvidenceSort,
  ServiceDiscovery,
  EvidenceGroupId,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { AuditRecord } from '@trustero/trustero-api-web/lib/audit/audit_pb'
import { TimeRange } from '@trustero/trustero-api-web/lib/common/time_pb'
import {
  Evidence,
  Source,
  Sources,
  Struct,
} from '@trustero/trustero-api-web/lib/receptor_v1/receptor_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { TDocumentDefinitions } from 'pdfmake/interfaces'
import { ParsedQuery } from 'query-string'
import {
  DiscoveriesToMarkdown,
  ExclusionsToMarkdown,
  getColumnWidths,
  getNoEvidenceMessage,
} from 'src/components/ModalForms/Evidence/ViewEvidenceForm/ViewEvidenceForm.utils'
import {
  GridColumnSortEvidence,
  GridColumnSortType,
  SORT_ORDER,
} from 'src/components/Reusable/Grid/GridColumnSort/GridColumnSort.constants'
import { FilterParam } from 'src/components/Reusable/IndexPage/FilterBar/FilterBar.types'
import {
  FileTypeFilterValues,
  FileTypeFilterValueToMimeTypes,
} from 'src/components/Reusable/IndexPage/FilterBar/FilterDropdowns/FileTypeFilterDropdown'
import {
  SourceFilterValues,
  sourceFilterValueToSourceType,
} from 'src/components/Reusable/IndexPage/FilterBar/FilterDropdowns/SourceFilterDropdown'
import { SelectItem } from 'src/components/Reusable/SelectDropdown/SelectDropdown.constants'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { DATE_FORMATS } from 'src/Utils/dateConstants'
import {
  getEvidenceSources,
  tabulate,
} from 'src/Utils/Evidence/evidence.helpers'
import {
  formatTimestamp,
  getTimestampFromUnixMilli,
} from 'src/Utils/formatDate'
import { getServiceTemplate } from 'src/xgenerated/service'
import { EVIDENCE_SORT_COLUMNS } from './evidence.constants'

export const applyEvidenceFilters = (
  req: ListEvidenceRequest | GetLatestEvidenceRequest,
  queryParams: ParsedQuery<string>,
  filterType: FilterParam,
): void => {
  const filterParams = queryParams[filterType] as string[]

  if (!filterParams?.length) {
    return
  }

  switch (filterType) {
    case FilterParam.CONTROL: {
      // for now, set only one control id
      req.setControlId(filterParams[0])
      break
    }
    case FilterParam.REQUEST: {
      // for now, set only one request id
      req.setDocumentRequestId(filterParams[0])
      break
    }

    default:
      break
  }
}

export const getControlIdForEvidence = (
  queryParams: ParsedQuery<string>,
): string => {
  const controlIds = queryParams[FilterParam.CONTROL]
  if (!controlIds || controlIds.length !== 1) {
    return ''
  }
  return controlIds[0]
}

export const getRequestIdForEvidence = (
  queryParams: ParsedQuery<string>,
): string => {
  const requestIds = queryParams[FilterParam.REQUEST]
  if (!requestIds || requestIds.length !== 1) {
    return ''
  }
  return requestIds[0]
}

export const isRelevantDateInAudit = (
  audit: AuditRecord,
  date?: Date,
): boolean => {
  const startDate = audit.getStartDate()
  const endDate = audit.getEndDate()
  if (!date || !startDate || !endDate) {
    return false
  }
  return date >= startDate.toDate() && date <= endDate.toDate()
}

export const applyEvidenceGroupFilters = (
  req: ListEvidenceGroupsRequest | GetLatestEvidenceGroupsRequest,
  queryParams: ParsedQuery<string>,
  filterType: FilterParam,
): void => {
  const filterParams = queryParams[filterType] as string[]

  if (!filterParams?.length) {
    return
  }
  switch (filterType) {
    case FilterParam.CONTROL: {
      if (filterParams.length) {
        const controlFilter = new ControlFilter().setControlIdsList([
          ...filterParams,
        ])
        req.setControlFilter(controlFilter)
      }
      break
    }
    case FilterParam.SOURCE: {
      if (filterParams.length) {
        const sourceFilter = new SourceFilter().setSourceTypesList(
          filterParams.map(
            (param) =>
              sourceFilterValueToSourceType[param as SourceFilterValues],
          ),
        )
        req.setSourceFilter(sourceFilter)
      }
      break
    }
    case FilterParam.FILE_TYPE: {
      if (filterParams.length) {
        const mimeTypes: string[] = []
        filterParams.forEach((param) => {
          const mimes =
            FileTypeFilterValueToMimeTypes[param as FileTypeFilterValues]
          Array.isArray(mimes) && mimeTypes.push(...mimes)
        })
        const fileTypeFilter = new FileTypeFilter().setMimeTypesList(mimeTypes)
        req.setFileTypeFilter(fileTypeFilter)
      }
      break
    }
    case FilterParam.DATE_RANGE: {
      if (filterParams.length) {
        const dates = filterParams[0].split('-')
        const dateRange = new TimeRange()
        const startMillis = parseInt(dates[0], 10)
        dateRange.setSince(getTimestampFromUnixMilli(startMillis))
        const endMillis = parseInt(dates[1], 10)
        dateRange.setUntil(getTimestampFromUnixMilli(endMillis))
        req.setDateRangeFilter(dateRange)
      }
      break
    }
    case FilterParam.OWNER: {
      if (filterParams.length) {
        req.setActorFilter(new ActorFilter().setActorEmailsList(filterParams))
      }
      break
    }
    default:
      break
  }
}

export const applyEvidenceGroupSort = (
  req: ListEvidenceGroupsRequest | GetLatestEvidenceGroupsRequest,
  queryParams: ParsedQuery<string>,
): void => {
  const sortOrder =
    (queryParams.sort_by && (queryParams.sort_by[0] as GridColumnSortType)) ||
    undefined
  const sortCol =
    (queryParams.sort_col &&
      (queryParams.sort_col[0] as GridColumnSortEvidence)) ||
    undefined
  const shouldApplySort = sortOrder && sortCol
  if (!shouldApplySort) {
    return
  }
  const sort = new EvidenceSort()
  sort.setSortOrder(SORT_ORDER[sortOrder])
  sort.setSortColumn(EVIDENCE_SORT_COLUMNS[sortCol])
  req.setSort(sort)
}

export const generateEvidencePdfWithExclusions = async (
  body: Uint8Array | string,
  caption: string,
  exclusionsList: Excluded[],
): Promise<TDocumentDefinitions> => {
  const evidence = Evidence.deserializeBinary(body as Uint8Array) // should always be Uint8Array from ContentStore
  const evidenceSources = getEvidenceSources(evidence)
  const apiSections = evidenceSources.map((source) => [
    {
      text: 'API Call',
      style: 'subHeading',
    },
    {
      text: source.getApiCallsList().join('\n'),
      style: 'body',
    },
    {
      text: 'API Response',
      style: 'subHeading',
    },
    {
      text: JSON.stringify(JSON.parse(source.getDiscovery() || '{}'), null, 2),
      preserveLeadingSpaces: true,
      style: 'json',
    },
  ])
  const evidenceStruct: Struct = evidence.getStruct() ?? new Struct()
  const noEvidence = evidenceStruct.getRowsList().length === 0
  const noEvidenceMessage = getNoEvidenceMessage(evidence)
  const evidenceTable = tabulate(evidenceStruct)
  const exclusions = ExclusionsToMarkdown(
    exclusionsList,
    (id: string) => getServiceTemplate(id)?.name || 'Unknown Service',
  )
  const evidenceSection = noEvidence
    ? { text: [noEvidenceMessage], style: 'body' }
    : {
        table: {
          widths: getColumnWidths(evidenceTable),
          body: [
            evidenceTable.headers,
            ...evidenceTable.body.map((row) =>
              row.map((cell) => cell.replaceAll(':heavy_check_mark:', 'Y')),
            ),
          ],
          headerRows: 1,
          layout: 'fit',
        },
        style: 'table',
      }

  return {
    content: [
      { text: caption, style: 'title' },
      { text: 'Evidence', style: 'heading' },
      evidenceSection,
      { text: 'Exclusions', style: 'heading' },
      { text: [exclusions], style: 'body' },
      { text: 'Sources', style: 'heading' },
      ...apiSections,
    ],
    styles: {
      title: { fontSize: 18, bold: true, margin: [0, 0, 0, 10] },
      heading: { fontSize: 16, bold: true, margin: [0, 10, 0, 5] },
      subHeading: { fontSize: 12, bold: true, margin: [0, 5, 0, 5] },
      body: { fontSize: 10, margin: [0, 0, 0, 10] },
      table: { fontSize: 10, margin: [0, 0, 0, 10] },
      json: {
        fontSize: 10,
        margin: [0, 0, 0, 10],
      },
    },
  }
}

export const getEvidenceVersionDropdownOptions = (
  data?: ListEvidenceResponse | ListEvidenceGroupsResponse,
): SelectItem[] => {
  if (!data) return []
  return data
    ?.getItemsList()
    .sort((a, b) => {
      const aCreatedAt = a.getCreatedAt()
      const bCreatedAt = b.getCreatedAt()
      if (!aCreatedAt || !bCreatedAt) return 0
      return bCreatedAt.toDate().getTime() - aCreatedAt.toDate().getTime()
    })
    .map((evidence: EvidenceProto | EvidenceGroup) => ({
      name: formatTimestamp(
        evidence.getCreatedAt() || new Timestamp(),
        DATE_FORMATS.ISO_WITH_TIME,
      ),
      value: evidence.getId(),
    })) as SelectItem[]
}

export const getManualEvidenceDiscoveries = (
  sources: Source[],
): ServiceDiscovery[] => {
  const discoveries: ServiceDiscovery[] = []
  sources.forEach((source) => {
    discoveries.push(
      new ServiceDiscovery()
        .setApiCallsList([source.getRawApiRequest()])
        .setDiscovery(source.getRawApiResponse()),
    )
  })
  return discoveries
}

export const getSourcesMarkdown = (
  evidenceBody: Uint8Array,
  isMultipart: boolean,
): string => {
  const discoveries: ServiceDiscovery[] = []
  if (isMultipart) {
    const sources = Sources.deserializeBinary(evidenceBody)
    const evidenceDiscoveries = getManualEvidenceDiscoveries(
      sources.getSourcesList(),
    )
    discoveries.push(...evidenceDiscoveries)
  } else {
    const evidence = Evidence.deserializeBinary(evidenceBody)
    const evidenceDiscoveries = getEvidenceSources(evidence)
    discoveries.push(...evidenceDiscoveries)
  }
  const sourcesMarkdown = DiscoveriesToMarkdown(discoveries)
  return sourcesMarkdown
}

export const getEvidenceGroupId = ({
  caption,
  contentId,
  discoveryId,
}: {
  caption: string
  contentId: string
  discoveryId?: string
}): EvidenceGroupId => {
  const evidenceGroupId = new EvidenceGroupId()
    .setCaption(new StringValue().setValue(caption))
    .setContentId(new StringValue().setValue(contentId))
  !!discoveryId &&
    evidenceGroupId.setDiscoveryId(new StringValue().setValue(discoveryId))
  return evidenceGroupId
}
