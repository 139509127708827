import React from 'react'
import { StandardOpenModalButton } from 'src/components/ModalForms/ModalButtons'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { ModalFormId } from 'src/components/ModalForms'
import { StandardButtonSize } from 'src/components/Reusable/Buttons'
import { E2E_IDS } from 'src/Utils/testIds'
import { EditableShowPageTitle } from 'src/components/Reusable/ShowPage/Title/EditableShowPageTitle'
import { Risk, RISK_MATH } from '@trustero/trustero-api-web/lib/risk/risk_pb'
import { SubRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import { useNavigate } from 'react-router-dom'
import { RiskFields } from './risks.constants'
import { RisksMathMenu } from './menus'
import { getRiskMathCalculation } from './risks.helpers'
import { useCreateOrUpdateRisks, useInvalidateRisksCache } from './risks.hooks'

export const AddRiskModalBtn = ({
  text,
  handleClick,
}: {
  text: string
  handleClick: () => void
}): JSX.Element => (
  <StandardOpenModalButton
    modalId={ModalFormId.ADD_RISK}
    size={StandardButtonSize.SMALL}
    text={text}
    testId={E2E_IDS.ADD_RISK}
    onClick={handleClick}
  />
)
/**
 * Helper component to display the Inherent Risk which is based off impact & likelihood
 *
 * The formula essentially takes an average of the two values and returns a RISK_MATH calculation
 *
 * @param impact
 * @param likelihood
 * @returns <RiskMathMenu /> component
 */
export const CalculatedRiskMathChip = ({
  riskId,
  impact,
  likelihood,
  field,
}: {
  riskId: string
  impact: RISK_MATH
  likelihood: RISK_MATH
  field: RiskFields
}): JSX.Element => (
  <RisksMathMenu
    riskId={riskId}
    field={field}
    initValue={getRiskMathCalculation(impact, likelihood)}
    isReadOnly
    hasNoBorder
  />
)

export const RisksShowTitle = ({
  id,
  name,
  customId,
}: {
  id: string
  name: string
  customId: string
}): JSX.Element => {
  const createOrUpdateRisks = useCreateOrUpdateRisks(false, false)
  const navigate = useNavigate()
  const mutate = useInvalidateRisksCache()

  const onUpdate = async (newName: string, newCustomId?: string) => {
    const updatedRisk = new Risk()
    updatedRisk.setId(id)
    updatedRisk.setName(newName)
    newCustomId && updatedRisk.setCustomId(newCustomId)
    await createOrUpdateRisks([updatedRisk])
    if (customId) {
      navigate(`../../${SubRoutes.SHOW}/${id}`)
    }
    await mutate()
  }

  return (
    <EditableShowPageTitle
      name={name}
      customId={customId}
      modelType={MODEL_TYPE.RISK}
      onUpdate={onUpdate}
    />
  )
}
