import { isFunction } from 'lodash'
import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { LinkProps } from 'react-router-dom'

export const ScrollRestoringLink = ({
  to,
  children,
  onClick,
  ...props
}: LinkProps): JSX.Element => {
  const location = useLocation()

  useEffect(() => {
    const savedPosition = sessionStorage.getItem(
      `scrollPosition-${location.pathname}`,
    )
    if (savedPosition) {
      window.scrollTo(0, parseInt(savedPosition, 10))
      sessionStorage.removeItem(`scrollPosition-${location.pathname}`)
    }
  }, [location])

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    sessionStorage.setItem(
      `scrollPosition-${location.pathname}`,
      window.scrollY.toString(),
    )
    isFunction(onClick) && onClick(e)
  }

  return (
    <Link to={to} onClick={handleClick} {...props}>
      {children}
    </Link>
  )
}
