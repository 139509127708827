import { useCallback, useMemo } from 'react'
import {
  EvidenceGroupId,
  GetLatestEvidenceGroupsRequest,
  GetLatestEvidenceGroupsResponse,
  GetLatestEvidenceRequest,
  ListEvidenceGroupsRequest,
  ListEvidenceGroupsResponse,
  ListEvidenceRequest,
  UpdateDocumentRequest,
  UpdateEvidenceGroupRequest,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import { useLocation, useParams } from 'react-router-dom'
import queryString, { ParsedQuery } from 'query-string'
import { FilterParam } from 'src/components/Reusable/IndexPage/FilterBar/FilterBar.types'
import {
  BoolValue,
  StringValue,
} from 'google-protobuf/google/protobuf/wrappers_pb'
import { GrpcResponse, NewGrpcResponse } from 'src/components/async/hooks/types'
import {
  AuditRecords,
  EvidenceFilter,
  GetAuditsRequest,
} from '@trustero/trustero-api-web/lib/audit/audit_pb'
import { useSwrImmutableGrpc } from 'src/components/async/useSwrImmutableGrpc'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { useGrpcRevalidateByMethod } from 'src/components'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { AuditPromiseClient } from '@trustero/trustero-api-web/lib/audit/audit_grpc_web_pb'
import { useHardEvidenceInvalidation } from 'src/Utils/swrCacheInvalidation/useInvalidateEvidence'
import { useAuthorizedGrpcClient } from 'src/adapter/grpcClient'
import {
  generatePermalink,
  useNavigateWithSearch,
} from 'src/components/PageLayout/Permalink'
import { useFetchDocumentBodyAllowMultipart } from 'src/components/async/document/useDocument'
import { isParsedPartArray } from 'src/Utils/globalHelpers'
import { isEvidenceFile } from 'src/components/ModalForms/Evidence/evidenceUtils'
import { documentBodyAsFile } from 'src/adapter/AttachmentAdapter'
import {
  applyEvidenceFilters,
  applyEvidenceGroupFilters,
  applyEvidenceGroupSort,
  getSourcesMarkdown,
} from './evidence.helpers'
import { EvidenceConfigType } from './evidence.constants'

export const useInvalidateEvidenceGroups = (): (() => Promise<void>) => {
  const mutateFunc = useGrpcRevalidateByMethod()

  return useCallback(async () => {
    await Promise.all([
      mutateFunc(AttachmentPromiseClient.prototype.getLatestEvidenceGroups),
      mutateFunc(AttachmentPromiseClient.prototype.listEvidenceGroups),
    ])
  }, [mutateFunc])
}

export const useLatestEvidenceRequest = (): GetLatestEvidenceRequest => {
  const location = useLocation()

  return useMemo(() => {
    const req = new GetLatestEvidenceRequest()
    const queryParams: ParsedQuery<string> = queryString.parse(
      location.search,
      {
        arrayFormat: 'bracket',
      },
    )
    // Apply all filters for each parameter in URL
    Object.values(FilterParam).forEach((filterType: FilterParam) =>
      applyEvidenceFilters(req, queryParams, filterType),
    )
    return req
  }, [location.search])
}

export const useEvidenceRequest = (): ListEvidenceRequest => {
  const location = useLocation()

  return useMemo(() => {
    const req = new ListEvidenceRequest()
    const queryParams: ParsedQuery<string> = queryString.parse(
      location.search,
      {
        arrayFormat: 'bracket',
      },
    )
    // Apply all filters for each parameter in URL
    Object.values(FilterParam).forEach((filterType: FilterParam) =>
      applyEvidenceFilters(req, queryParams, filterType),
    )
    return req
  }, [location.search])
}

export const useEvidenceAudits = ({
  caption,
  contentId,
  discoveryId,
  relevantDate,
}: {
  caption: string
  contentId: string
  discoveryId: string
  relevantDate: Timestamp
}): GrpcResponse<AuditRecords> => {
  const req = useMemo(() => {
    const evidenceFilter = new EvidenceFilter()
      .setCaption(caption)
      .setContentId(contentId)
      .setDiscoveryId(discoveryId)
      .setRelevantDate(relevantDate)
    const req = new GetAuditsRequest()
    req
      .setEvidenceFilter(evidenceFilter)
      .setIsClosed(new BoolValue().setValue(false))
    return req
  }, [caption, contentId, discoveryId, relevantDate])

  const { response } = useSwrImmutableGrpc(
    AuditPromiseClient.prototype.getAudits,
    req,
    true,
  )
  return NewGrpcResponse(response)
}

export const useEvidenceGroupsRequest = (): ListEvidenceGroupsRequest => {
  const location = useLocation()

  return useMemo(() => {
    const req = new ListEvidenceGroupsRequest()
    const queryParams: ParsedQuery<string> = queryString.parse(
      location.search,
      {
        arrayFormat: 'bracket',
      },
    )
    // Apply all filters for each parameter in URL
    Object.values(FilterParam).forEach((filterType: FilterParam) =>
      applyEvidenceGroupFilters(req, queryParams, filterType),
    )
    applyEvidenceGroupSort(req, queryParams)
    return req
  }, [location.search])
}

export const useLatestEvidenceGroupsRequest =
  (): GetLatestEvidenceGroupsRequest => {
    const location = useLocation()

    return useMemo(() => {
      const req = new GetLatestEvidenceGroupsRequest()
      const queryParams: ParsedQuery<string> = queryString.parse(
        location.search,
        {
          arrayFormat: 'bracket',
        },
      )
      // Apply all filters for each parameter in URL
      Object.values(FilterParam).forEach((filterType: FilterParam) =>
        applyEvidenceGroupFilters(req, queryParams, filterType),
      )
      applyEvidenceGroupSort(req, queryParams)
      return req
    }, [location.search])
  }

export const useLatestEvidenceGroups = (
  req: GetLatestEvidenceGroupsRequest = new GetLatestEvidenceGroupsRequest(),
  shouldFetch = true,
): GrpcResponse<GetLatestEvidenceGroupsResponse> => {
  const { response } = useSwrImmutableGrpc(
    AttachmentPromiseClient.prototype.getLatestEvidenceGroups,
    req,
    shouldFetch,
  )
  return NewGrpcResponse(response)
}

export const useEvidenceGroups = (
  req: ListEvidenceGroupsRequest = new ListEvidenceGroupsRequest(),
  shouldFetch = true,
): GrpcResponse<ListEvidenceGroupsResponse> => {
  const { response } = useSwrImmutableGrpc(
    AttachmentPromiseClient.prototype.listEvidenceGroups,
    req,
    shouldFetch,
  )
  return NewGrpcResponse(response)
}

export const useUpdateEvidence = ({
  id,
  isEvidenceGroup,
  caption,
  contentId,
  discoveryId,
  requestId,
}: {
  id?: string
  isEvidenceGroup: boolean
  caption: string
  contentId: string
  discoveryId?: string
  requestId?: string
}): (({
  newCaption,
  relevantDate,
}: {
  newCaption?: string
  relevantDate?: Timestamp
}) => Promise<void>) => {
  const navigate = useNavigateWithSearch()
  const { pageContext } = useParams()
  const client = useAuthorizedGrpcClient(AttachmentPromiseClient)
  const mutate = useHardEvidenceInvalidation()
  const mutateGroups = useInvalidateEvidenceGroups()
  if (isEvidenceGroup) {
    return async ({
      newCaption,
      relevantDate,
    }: {
      newCaption?: string
      relevantDate?: Timestamp
    }) => {
      const groupId = new EvidenceGroupId()
        .setCaption(new StringValue().setValue(caption))
        .setContentId(new StringValue().setValue(contentId))
      discoveryId &&
        groupId.setDiscoveryId(new StringValue().setValue(discoveryId))
      const request = new UpdateEvidenceGroupRequest().setId(groupId)
      relevantDate && request.setRelevantDate(relevantDate)
      newCaption && request.setCaption(new StringValue().setValue(newCaption))
      const updated = await client.updateEvidenceGroup(request)
      await mutateGroups()
      if (updated && updated.getId() != id) {
        const evidencePermalink = generatePermalink({
          pageContext: pageContext as string,
          modelType: MODEL_TYPE.EVIDENCE,
          modelId: encodeURI(updated.getId()),
          isInternalLink: true,
        })
        navigate(evidencePermalink)
      }
    }
  } else {
    return async ({
      newCaption,
      relevantDate,
    }: {
      newCaption?: string
      relevantDate?: Timestamp
    }) => {
      const request = new UpdateDocumentRequest()
      id && request.setId(id)
      requestId && request.setRequestId(new StringValue().setValue(requestId))
      newCaption && request.setCaption(new StringValue().setValue(newCaption))
      relevantDate && request.setRelevantDate(relevantDate)
      await client.updateDocument(request)
      await mutate()
    }
  }
}

export const useEvidenceConfig = (
  contentId: string,
  mime: string,
  isAutomated: boolean,
  caption: string,
): (() => Promise<EvidenceConfigType | null>) => {
  const fetchDocumentBody = useFetchDocumentBodyAllowMultipart(contentId, mime)
  return useCallback(async () => {
    const docBody = await fetchDocumentBody()
    if (docBody) {
      if (isParsedPartArray(docBody)) {
        const bodyPart = docBody.find((part) =>
          part.headers.get('content-disposition')?.includes('file'),
        )
        const sourcesPart = docBody.find((part) =>
          part.headers
            .get('content-disposition')
            ?.includes('receptor_v1.Sources'),
        )
        if (!bodyPart || !sourcesPart) return null
        const body = bodyPart.body
        const mimeType = bodyPart.headers.get('content-type') || ''
        const sourcesBytes = sourcesPart.body as Uint8Array
        const isMultipartAutomated = mimeType === 'application/protobuf'
        const sourcesMarkdown = getSourcesMarkdown(sourcesBytes, true)
        const file = isEvidenceFile(mimeType)
          ? documentBodyAsFile(body, caption, mimeType)
          : null
        return {
          body,
          mimeType,
          sourcesMarkdown,
          isAutomated: isMultipartAutomated,
          file,
        }
      }
      const file = isEvidenceFile(mime)
        ? documentBodyAsFile(docBody, caption, mime)
        : null
      return {
        body: docBody,
        mimeType: mime,
        sourcesMarkdown: '',
        isAutomated,
        file,
      }
    }
    return null
  }, [mime, isAutomated, fetchDocumentBody, caption])
}
